import React from 'react'
import { useTheme } from 'styled-components/native'
import { CellRendererProps, TableConfig } from '../../components/Table'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../components/Table/components/DynamicTable/SharedStyledComponents'
import { SmallRow } from '../../components/Table/components/SharedStyledComponents'
import useTranslation from '../../hooks/useTranslation'
import {
  Content,
  headerContainerStyle,
  headerStyle
} from './components/SharedComponents'
import {
  EngineEnum,
  getEngineHitKey,
  selectItemColumn
} from '../CallForSubmission/constants'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import TitleCell, {
  LabelAndTitleCell
} from '../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
import {
  CallForSubmissionStepEnum,
  InnovationSubmissionStatusEnum
} from '../../types'
import { csvDisplayDate, displayDate } from '../../ui-library/DateInput/utils'
import TouchableIcon from '../../components/icon/TouchableIcon'
import { View } from 'react-native'
// import useInnovationCategoriesForCFSAndAdvisorQuery from '../CallForSubmission/hooks/innovationCategoriesForCFSAndAdvisor'
import useDelegationsQuery from '../Product/hooks/useDelegationsQuery'
import useCategoriesQuery from './hooks/useCategoriesQuery'
// Fragments
import CompanyProfileFragment from '../../graphql/CompanyProfileFragment'
import DirectoryCompanyProfileFragment from '../../graphql/DirectoryCompanyProfileFragment'

const appSearchColumnMap = {
  id: 'id',
  companyId: 'company_id',
  companyName: 'company_name',
  createdAt: 'created_at',
  companyUrl: 'company_url',
  teamAdmins: 'team_admins_emails',
  teamAdminsIds: 'team_admins_ids',
  delegationsList: 'delegations_list',
  delegationIds: 'delegation_ids',
  delegationNames: 'delegation_names',
  categoryList: 'category_list',
  categoryIds: 'category_ids',
  categories: 'category_names',
  totalCFS: 'total_calls_for_submission',
  type: 'type',
  productName: 'product_name',
  updatedAt: 'updated_at'
}

const DEFAULT_DIR = 'asc'

export const DIRECTORY_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: 'innovator-directory-table',
  queryDynamicName: 'directoryProfileSearchInnovationEngine',
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

export const COMPANY_SUBMISSIONS_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: 'company-submissions-table',
  queryDynamicName: '',
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

const lastUpdatedColumn = {
  id: 'lastUpdatedColumn',
  header: 'lastUpdatedColumn',
  sort: {
    key: appSearchColumnMap['updatedAt'],
    defaultDir: 'desc',
    isDefault: true
  },
  isHidden: true,
  headerStyle,
  width: 80,
  emptyMessage: '',
  isEditable: false,
  fixed: true,
  Cell: () => {}
}

const submissionNameColumn = {
  id: 'submissionNameColumn',
  header: 'innovatorDirectory:table:submissionsName',
  sort: {
    key: appSearchColumnMap['productName'],
    defaultDir: DEFAULT_DIR,
    isDefault: false
  },
  filter: {
    type: 'search',
    key: appSearchColumnMap['productName'],
    defaultValue: ''
  },
  headerStyle,
  width: 80,
  emptyMessage: '',
  isEditable: false,
  fixed: true,
  Cell: ({ item, isSmallScreen, selectItemProps }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const handleSelect =
      selectItemProps && selectItemProps?.onSelect
        ? selectItemProps?.onSelect
        : () => {}

    const label =
      item?.submissionInfo?.name ?? t('callsForSubmission:table:noName')
    const cfsName =
      item?.callForSubmission?.name ?? t('callsForSubmission:table:noName')

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={t('innovatorDirectory:table:submissionsName')}
        title={label}
        subtitle={cfsName}
        isValueHighlighted
        containerStyles={{
          borderTopWidth: 0
        }}
        onPress={() => handleSelect(item.id)}
      />
    ) : (
      <TitleCell
        onPress={() => handleSelect(item.id)}
        item={{ title: label, subtitle: cfsName }}
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    return `${item?.companyInfo?.name || '-'}`
  }
}

const submissionActionsColumn = {
  id: 'submissionActionsColumn',
  header: '',
  headerStyle,
  width: 20,
  emptyMessage: '',
  isEditable: true,
  Cell: ({ item, selectItemProps }) => {
    const { colors } = useTheme()
    const handleSelect =
      selectItemProps && selectItemProps?.onSelect
        ? selectItemProps?.onSelect
        : () => {}
    return (
      <View style={{ width: 16 }}>
        <TouchableIcon
          name="chevron-right"
          width={16}
          height={16}
          color={colors.primaryPalette.black}
          onPress={() => {
            handleSelect(item.id)
          }}
        />
      </View>
    )
  },
  getCsvData: () => {
    return `-`
  }
}

export const companyNameColumn = {
  id: 'companyColumn',
  header: 'innovatorDirectory:table:companyName',
  sort: {
    key: appSearchColumnMap['companyName'],
    defaultDir: DEFAULT_DIR,
    isDefault: true
  },
  filter: {
    type: 'search',
    key: appSearchColumnMap['companyName'],
    defaultValue: ''
  },
  headerStyle,
  width: 15,
  emptyMessage: '',
  isEditable: false,
  fixed: true,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const linkToScreen = useLinkToScreen()
    const onPress = () => {
      linkToScreen('InnovatorDirectoryProfile', {
        companyId: item?.companyInfo?.id
      })
    }

    const titleObj = {
      title: item?.companyInfo?.name,
      subtitle: undefined
    }

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={item?.submissionInfo?.name}
        subtitle={undefined}
        onPress={onPress}
        isValueHighlighted
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell item={titleObj} onPress={onPress} />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    return `${item?.companyInfo?.name || '-'}`
  }
}

const companyUrlColumn = {
  id: 'companyUrlColumn',
  header: 'innovatorDirectory:table:companyUrl',
  headerStyle,
  width: 20,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.url}
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    return `${item?.url || '-'}`
  }
}

const companyCreatedAtColumn = {
  id: 'companyCreatedAtColumn',
  header: 'innovatorDirectory:table:companyCreatedAt',
  sort: {
    key: appSearchColumnMap['createdAt'],
    defaultDir: DEFAULT_DIR,
    isDefault: false
  },
  filter: {
    type: 'search',
    key: appSearchColumnMap['createdAt'],
    defaultValue: ''
  },
  headerStyle,
  width: 20,
  emptyMessage: '',
  isEditable: false,
  fixed: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={
          item?.companyInfo?.createdAt
            ? displayDate(new Date(item?.companyInfo?.createdAt))
            : ''
        }
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    return item?.companyInfo?.createdAt
      ? `${csvDisplayDate(new Date(item?.companyInfo?.createdAt)) || '-'}`
      : '-'
  }
}

const teamAdminsColumn = {
  id: 'teamAdmins',
  header: 'innovatorDirectory:table:teamAdmins',
  headerStyle,
  width: 20,
  emptyMessage: '',
  isEditable: false,
  filter: {
    type: 'search',
    key: appSearchColumnMap['teamAdmins'],
    defaultValue: ''
  },
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const emails = item?.directoryInfo?.teamAdminEmails
      ? item?.directoryInfo?.teamAdminEmails?.join(',\n')
      : item?.directoryInfo?.teamAdmins?.map(m => m.email).join(',\n')

    const labelTranslated = label ? t(label) : ''
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={emails}
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    // JC the deliminator is different as CSV , is only for next columns
    return item?.directoryInfo?.teamAdmins?.map(m => m.email).join(';') || '-'
  }
}

export const delegationColumn = {
  id: 'delegationColumn',
  header: 'innovatorDirectory:table:delegation',
  headerStyle,
  width: 15,
  isEditable: false,
  role: null,
  filter: {
    type: 'filter',
    key: appSearchColumnMap['delegationIds'],
    isFilterArray: true,
    isHook: true,
    styles: { minHeight: 0, maxWidth: '33vw' },
    isMulti: true,
    hookOptions: {
      useHookQuery: useDelegationsQuery,
      hookParams: {},
      hookKey: 'delegations',
      labelKey: 'name',
      valueKey: 'id'
    },
    defaultValue: ''
  },
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    let titleLabel = item?.directoryInfo?.delegationNames
      ? item?.directoryInfo?.delegationNames.join(',\n')
      : item?.directoryInfo?.delegations
          ?.map(c => c.name)
          .sort()
          .join(',\n') || '-'

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={t(titleLabel)}
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    let titleLabel =
      item?.directoryInfo?.delegations?.map(c => c.name).join(';') || '-'

    return titleLabel
  }
}

export const categoryColumn = {
  id: 'categoryColumn',
  header: 'innovatorDirectory:table:category',
  headerStyle,
  width: 25,
  isEditable: false,
  role: null,
  filter: {
    type: 'filter',
    key: appSearchColumnMap['categoryIds'],
    isFilterArray: true,
    isMulti: true,
    defaultValue: '',
    isHook: true,
    styles: { minHeight: 0, maxWidth: '33vw' },
    hookOptions: {
      useHookQuery: useCategoriesQuery,
      hookKey: 'innovationCategories',
      labelKey: 'name',
      valueKey: 'id'
    }
  },
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    let titleLabel = item?.directoryInfo?.categoryNames
      ? item?.directoryInfo?.categoryNames.join(',\n')
      : item?.directoryInfo?.categories?.map(c => c.name).join(',\n') || '-'

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={t(titleLabel)}
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    let titleLabel =
      item?.directoryInfo?.categories?.map(c => c.name).join(';') || '-'

    return titleLabel
  }
}

export const totalCFSColumn = {
  sort: {
    key: appSearchColumnMap['totalCFS'],
    defaultDir: DEFAULT_DIR,
    isDefault: false
  },
  id: 'totalCFS',
  header: 'innovatorDirectory:table:totalCFS',
  headerStyle,
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    let value = item?.directoryInfo?.totalCallsForSubmission || '-'

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={t(value)}
      />
    )
  },
  getCsvData: ({ item }: CellRendererProps<any>) => {
    let value = item?.directoryInfo?.totalCallsForSubmission || '-'

    return value
  }
}

interface TableConfigParams {
  engine: EngineEnum
  isDirectoryImprovementsEnabled?: boolean
}
export const getInnovatorDirectoryTableConfig = ({
  engine,
  isDirectoryImprovementsEnabled = false
}: TableConfigParams) => {
  const table = Object.assign({}, DIRECTORY_TABLE_CONFIG)
  table.enableFilters = true
  table.engine = engine
  table.isSticky = true
  table.hitKey = getEngineHitKey(engine)
  table.showTotalElements = true
  table.enableSelectAllElements = true
  table.queryConditions = {
    withDirectoryTypesenseData: isDirectoryImprovementsEnabled
  }
  table.customQueryFields = isDirectoryImprovementsEnabled
    ? DirectoryCompanyProfileFragment
    : CompanyProfileFragment
  table.columns = [
    selectItemColumn,
    companyNameColumn,
    companyUrlColumn,
    teamAdminsColumn,
    delegationColumn,
    categoryColumn,
    companyCreatedAtColumn,
    totalCFSColumn
  ] as any[]

  table.showPDFButton = false
  table.showProductPDFButton = false

  table.filters = {
    all: [
      {
        is_innovator_company: 'true'
      }
    ]
  }

  return table
}

interface CompanySubmissionsTableConfigProps {
  companyId: string
  onRowSelect?: (value: any) => any
}

export const getCompanySubmissionsTableConfig = ({
  companyId,
  onRowSelect
}: CompanySubmissionsTableConfigProps) => {
  const table = Object.assign({}, COMPANY_SUBMISSIONS_TABLE_CONFIG)
  table.enableFilters = true
  table.engine = EngineEnum.InnovationSubmission
  table.hitKey = getEngineHitKey(EngineEnum.InnovationSubmission)

  table.columns = [
    lastUpdatedColumn,
    submissionNameColumn,
    submissionActionsColumn
  ] as any[]

  table.showPDFButton = false

  table.filters = {
    all: [
      {
        company_id: companyId
      },
      {
        has_been_submitted: 'true'
      }
    ],
    any: [
      {
        none: [
          {
            submission_step: CallForSubmissionStepEnum.submission
          }
        ]
      },
      {
        submission_status: InnovationSubmissionStatusEnum.submitted
      }
    ],
    none: []
  }

  table.showCsvButton = false
  table.rowHeight = 40
  table.onRowSelect = onRowSelect

  return table
}
