import React, { useCallback, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/native'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import useCurrentQueryTable from '../../hooks/useCurrentQueryTable'
import Checkbox from '../../ui-library/CheckBox'

import {
  AppSearchFiltersInput,
  CellRendererProps,
  mergeFilters,
  TableConfig
} from '../../components/Table'
import useTranslation from '../../hooks/useTranslation'
import TitleCell, {
  LabelAndTitleCell,
  SubtitleCell
} from '../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
import {
  appSearchColumnMap,
  EngineEnum,
  getEngineHitKey,
  newSelectItemColumn,
  selectItemColumn
} from '../CallForSubmission/constants'

import { SmallRow } from '../../components/Table/components/SharedStyledComponents'
import { AlternatingHoverableRow as DynamicAlternatingHoverableRow } from '../../components/DynamicTable/components/Table/SharedStyledComponents'
import useLinkToScreen from '../../hooks/useLinkToScreen'
import {
  CallForSubmission,
  CallForSubmissionStepEnum,
  Group,
  GroupSelection,
  InnovationSubmissionStatusEnum,
  RoleEnum,
  SubmissionVotingVoteEnum
} from '../../types'
import CohortCheckBox from './components/CohortCheckBox'
import NewCohortCheckBox from './components/NewCohortCheckBox'
import Theme from '../../constants/Theme'
import TouchableIcon from '../../components/icon/TouchableIcon'

import SendEmailDialog from '../CallForSubmission/SettingsScreen/components/SendEmailDialog'
import { Text } from '../../components/common/Text'
import { Question } from '../../types/form'
import { CSV_SEPARATOR_CHARACTER } from '../../components/Table/components/constants'
import useCurrentUser from '../../hooks/useCurrentUser'
import useSelectSubmissionForCohortMutation from './hooks/useSelectSubmissionForCohortMutation'
import InnovationSubmissionFieldsFragment from './graphql/CurationSubmissionsQuery'
import InnovationSubmissionCohortFieldsFragment from './graphql/CurationCohortQuery'
import InnovationSubmissionPresentationsFieldsFragment from './graphql/CurationPresentationsQuery'
import InnovationSubmissionAdviseFieldsFragment from '../Advise/graphql/AdviseInnovationSubmissionsQuery'
import { CSVTypeEnum, TableNameEnum } from '../../components/DynamicTable/types'
import {
  useGetPersistedPresentationsSelection,
  usePresentationsPersistSelect
} from './hooks/usePresentationsPersistSelect'
import {
  advisorStatusFilterOptions,
  advisorStatusFilters
} from '../../components/DynamicTable/components/SearchBar/constants'
import useInnovationCategoriesForCFSAndAdvisorQuery from '../CallForSubmission/hooks/innovationCategoriesForCFSAndAdvisor'
import Icon from '../../components/icon'
import { FilterActionsEnum } from '../../components/DynamicTable/hooks/useFilter'

const CheckboxesView = styled(View)`
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const CheckboxView = styled(View)`
  align-items: center
    ${({ width }) => `
    width: ${width || '100%'};
  `};
`

const DetailItemView = styled.View`
  display: block;
  ${({ leftTable, width, margin }) => `
    width: ${leftTable ? 'auto' : width ?? '13vw'};
    justify-content: ${leftTable ? 'center' : 'flex-start'};
    margin: ${margin ?? 'auto'}
  `};
`

const defaultDir = 'asc'

const headerStyle = {
  fontWeight: 'bold',
  fontSize: `${Theme.fontSizes[2]}px`,
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block',
  textTransform: 'uppercase',
  textAlign: 'center',
  textAlignVertical: 'center',
  width: '100%'
}

const headerContainerStyle = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 10
}

interface ContentProps {
  isSmallScreen?: boolean
  label: string
  title: string
  style?: object | object[]
  subtitle?: string | JSX.Element
}

const Content = ({
  isSmallScreen,
  label,
  title,
  style = {},
  subtitle
}: ContentProps) => {
  return isSmallScreen ? (
    <LabelAndTitleCell label={label} title={title} subtitle={subtitle} />
  ) : (
    <SubtitleCell style={style} text={title} />
  )
}

const adviseColumn = {
  id: 'adviseColumn',
  header: 'curation:submissions:table:advise',
  headerStyle,
  sort: {
    key: appSearchColumnMap['passOrCutText'],
    defaultDir,
    isDefault: false
  },
  width: 15,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors } = useTheme()
    let textStyles = {}
    switch (item.passOrCutText) {
      case 'Fast Pass':
        textStyles = { textAlign: 'center' }
        break
      case 'Pass':
        textStyles = {
          ...textStyles,
          color: colors.success,
          textAlign: 'center'
        }
        break
      case 'Cut':
        textStyles = {
          ...textStyles,
          color: colors.danger,
          textAlign: 'center'
        }
        break
      case 'Flagged':
        textStyles = { textAlign: 'center' }
        break
      default:
        textStyles = { textAlign: 'center' }
        break
    }

    return (
      <Text testID={'passOrCut'} styles={textStyles}>
        {item.passOrCutText ?? '-'}
      </Text>
    )
  }
}

export const companyColumn = {
  id: 'companyColumn',
  header: 'curation:submissions:table:companyName',
  sort: {
    key: appSearchColumnMap['companyName'],
    defaultDir,
    isDefault: true
  },
  filter: {
    type: 'search',
    key: appSearchColumnMap['companyName'],
    defaultValue: ''
  },
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 15,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.company?.name}
      />
    )
  }
}

const contactsColumn = {
  id: 'contactsColumn',
  header: 'curation:submissions:table:contacts',
  headerStyle: { ...headerStyle, textAlign: 'start', paddingTop: 2 },
  width: 20,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const emails = item?.company?.innovatorMembers
      .filter(m => m.user?.roles?.includes(RoleEnum.InnovatorTeamAdmin))
      .map(m => m.emailAddresses[0]?.email)
      .join(',\n')

    const labelTranslated = label ? t(label) : ''
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={emails}
      />
    )
  }
}

export const productColumn = {
  id: 'productColumn',
  header: 'curation:submissions:table:product',
  sort: {
    key: appSearchColumnMap['submissionName'],
    defaultDir,
    isDefault: false
  },
  filter: {
    type: 'search',
    key: appSearchColumnMap['productName'],
    defaultValue: ''
  },
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({
    item,
    label,
    isSmallScreen,
    role,
    queryDynamicName,
    tableName
  }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const { setCurrentQueryTable } = useCurrentQueryTable()
    const labelTranslated = label ? t(label) : ''
    const linkToScreen = useLinkToScreen()
    const routeName =
      role === RoleEnum.InnovationAdvisor
        ? 'AdviseSubmissionDetails'
        : 'CurationSubmissionDetails'

    const onPress = () => {
      setCurrentQueryTable(queryDynamicName)
      linkToScreen(routeName, {
        submissionId: item?.id,
        tableName: tableName,
        id: item?.callForSubmissionId
      })
    }

    const titleObj = {
      title: item?.submissionInfo?.name,
      subtitle: undefined
    }

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={item?.submissionInfo?.name}
        subtitle={undefined}
        onPress={onPress}
        isValueHighlighted
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell item={titleObj} onPress={onPress} />
    )
  }
}

export const getCategoryColumn = (params?) => ({
  id: 'categoryColumn',
  header: 'curation:submissions:table:category',
  sort: {
    key: appSearchColumnMap['categoryLabel'],
    defaultDir,
    isDefault: false
  },
  filter:
    params?.cfsId && params?.advisorUserId
      ? {
          type: 'filter',
          key: appSearchColumnMap['category'],
          isFilterArray: true,
          defaultValue: '',
          isHook: true,
          isMulti: true,
          styles: {
            minHeight: 0,
            maxWidth: '25vw'
          },
          handleChange: (selection, setQueryFilters) => {
            const value = selection['category']
            if (value && value.length) {
              setQueryFilters(selection)
            } else {
              // if selection is empy(empty array) clear filter by setting it undefined
              setQueryFilters({
                category: undefined
              })
            }
          },

          hookOptions: {
            useHookQuery: useInnovationCategoriesForCFSAndAdvisorQuery,
            hookParams: {
              cfsId: params.cfsId,
              advisorUserId: params.advisorUserId
            },
            hookKey: 'innovationCategories',
            labelKey: 'name',
            valueKey: 'id'
          }
        }
      : null,
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    let submissionInfo = item?.submissionInfo

    let titleLabel = submissionInfo?.categoryLabel || '-'

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={t(titleLabel)}
      />
    )
  }
})

const statusColumn = {
  id: 'statusColumn',
  header: 'curation:submissions:table:status',
  headerStyle: { ...headerStyle, paddingTop: 2 },
  sort: {
    key: appSearchColumnMap['isSubmissionStatusComplete'],
    defaultDir,
    isDefault: false
  },
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const { colors } = useTheme()

    const titleLabel =
      !item?.hasBeenSubmitted ||
      item?.step === CallForSubmissionStepEnum.submission
        ? 'submissions:status:inProgress'
        : 'submissions:status:submitted'
    let textStyles = {
      color:
        titleLabel === 'submissions:status:submitted'
          ? colors.success
          : colors.danger,
      textAlign: 'center'
    }

    return <Text styles={textStyles}>{t(titleLabel)}</Text>
  }
}

const isAdvisorFavoritedColumn = {
  id: 'advisorFavoriteColumn',
  header: 'curation:submissions:table:favorited',
  sort: null,
  headerStyle,
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item, isSmallScreen, ...rest }: CellRendererProps<any>) => {
    const isFavorite = item?.advisorsReviews
      ?.find(rev => rev.advisorId === rest['advisorId'])
      ?.answers.find(
        answer =>
          answer.questionId === rest['favoriteQuestionId'] && answer.value
      )
    const favorited = isFavorite ? 'Yes' : 'No'

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={favorited}
        title={favorited}
      />
    )
  }
}

const newIsAdvisorFavoritedColumn = {
  id: 'advisorFavoriteColumn',
  header: 'curation:submissions:table:favorited',
  sort: null,
  headerStyle,
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item, isSmallScreen }: CellRendererProps<any>) => {
    const isFavorite = item?.advisorSubmissionReview?.favorite

    const favorited = isFavorite ? 'Yes' : 'No'

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={favorited}
        title={favorited}
      />
    )
  }
}

const isAdvisorCheckedAcademyColumn = {
  id: 'isAdvisorCheckedAcademyColumn',
  header: 'curation:advisors:table:academyCount',
  sort: null,
  headerStyle,
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item, isSmallScreen, ...rest }: CellRendererProps<any>) => {
    const isFavorite = item?.advisorsReviews
      ?.find(rev => rev.advisorId === rest['advisorId'])
      ?.answers.find(
        answer =>
          answer.questionId === rest['academyQuestionId'] && answer.value
      )
    const favorited = isFavorite ? 'Yes' : 'No'

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={favorited}
        title={favorited}
      />
    )
  }
}

const newIsAdvisorCheckedAcademyColumn = {
  id: 'isAdvisorCheckedAcademyColumn',
  header: 'curation:advisors:table:academyCount',
  sort: null,
  headerStyle,
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item, isSmallScreen }: CellRendererProps<any>) => {
    const isAcademy = item?.advisorSubmissionReview?.academy

    const academy = isAcademy ? 'Yes' : 'No'

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={academy}
        title={academy}
      />
    )
  }
}

const hasAdvisorStartedColumn = {
  id: 'advisorHasStartedColumn',
  header: 'curation:submissions:table:status',
  sort: null,
  headerStyle,
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item, ...rest }: CellRendererProps<any>) => {
    const { colors } = useTheme()
    const hasAdvised = hasUserAdvised(item, rest['advisorId'])
    const status = hasAdvised ? 'Completed' : 'Not Started'
    const color = hasAdvised ? colors.success : colors.danger
    return <Text styles={{ color, textAlign: 'center' }}>{status}</Text>
  }
}

const newHasAdvisorStartedColumn = {
  id: 'advisorHasStartedColumn',
  header: 'curation:submissions:table:status',
  sort: null,
  headerStyle,
  width: 20,
  isEditable: false,
  role: null,
  Cell: ({ item }: CellRendererProps<any>) => {
    const hasAdvised = item?.advisorSubmissionReview?.hasAdvised ?? false
    const status = hasAdvised ? 'Completed' : 'Not Started'
    const color = hasAdvised ? Theme.colors.success : Theme.colors.danger
    return <Text styles={{ color, textAlign: 'center' }}>{status}</Text>
  }
}

const getReviewScoreByQuestionId = (submission, questionId) => {
  let score = '-'
  const submissionScore = submission?.submissionAverage?.submissionScores?.find(
    score => score?.question?.id === questionId
  )

  if (submissionScore && typeof submissionScore?.value === 'number') {
    score = submissionScore?.value.toFixed(2)
  }

  return score
}

/**
 * JC:We need to create columns based on the a review form questions
 */
export const buildDynamicQuestionColumn = (
  id,
  header,
  questionId,
  sortable = false
) => {
  return {
    id: id,
    header: header,
    headerStyle: {
      ...headerStyle
    },
    sort: sortable
      ? {
          key: appSearchColumnMap['reviewScore']`${header
            .split(' ')
            .join('_')
            .replace(/[\W_]+/g, ' ') // remove non alpha numeric characters
            .replaceAll('__', '_') // remove double underscores
            .replaceAll(' ', '_') // remove remaining whitespaces
            .toLowerCase()}`,
          defaultDir,
          isDefault: false
        }
      : null,
    width: 9,
    emptyMessage: '',
    isEditable: false,
    Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
      const { t } = useTranslation()
      const score = getReviewScoreByQuestionId(item, questionId)

      const labelTranslated = label ? t(label) : ''

      return (
        <Content
          style={{ textAlign: 'center' }}
          isSmallScreen={isSmallScreen}
          label={labelTranslated}
          title={score}
        />
      )
    }
  }
}

const getWASS = submission => {
  return submission?.submissionAverage?.wass
    ? submission?.submissionAverage?.wass.toFixed(2)
    : '-'
}
const wasColumn = {
  id: 'wasColumn',
  header: 'curation:submissions:table:wass',
  headerStyle: { ...headerStyle, textAlign: 'center', marginLeft: 5 },
  sortContainerStyle: { justifyContent: 'center' },
  sort: {
    key: appSearchColumnMap['wass'],
    defaultDir,
    isDefault: false
  },
  width: 15,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    let score = getWASS(item)

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={score}
      />
    )
  }
}

const getPresentationFlagSelectionColumn = ({
  flag,
  groupId,
  handleUpdate,
  header
}) => ({
  id: 'presentationFlagSelectionColumn',
  width: 10,
  header,
  headerStyle: {
    ...headerStyle,
    textAlign: 'center'
  },
  emptyMessage: '',
  isEditable: false,
  cellStyle: { margin: '0px 0px 3px 0px;' },
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { setPresentations } = usePresentationsPersistSelect()
    const persistedValue = useGetPersistedPresentationsSelection({
      submissionId: item.id,
      groupId,
      flag
    })
    const group = item?.cohorts?.find(c => c.group?.id === groupId) ?? {}
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [isSelected, setIsSelected] = useState<boolean | undefined>(
      persistedValue !== null ? persistedValue : group[flag]
    )

    const isPassSelectedValue = useMemo(() => isSelected ?? false, [isSelected])

    const onToggle = async () => {
      if (item?.id) {
        const value = !isSelected
        setIsLoadingUpdate(true)
        const errors = await handleUpdate({
          flag,
          variables: {
            id: item?.id,
            value,
            groupId
          }
        })
        if (!errors) {
          setPresentations({
            submissionId: item?.id,
            flag,
            groupId,
            value
          })
          setIsSelected(value)
        }
        setIsLoadingUpdate(false)
      }
    }

    const isLoading = isLoadingUpdate
    const checkboxColor = Theme.colors.success

    const Checkboxes = () => (
      <CheckboxesView>
        <CheckboxView width="100%">
          {isLoading ? (
            <ActivityIndicator
              style={{ alignItems: 'baseline' }}
              size="small"
            />
          ) : (
            <Checkbox
              checked={isPassSelectedValue}
              onPress={onToggle}
              isReadOnly={isLoading}
              flexContainerStyles={{
                paddingLeft: 0,
                marginBottom: 0
              }}
              checkedIconName=""
              checkedColor={checkboxColor}
              uncheckedColor={checkboxColor}
            />
          )}
        </CheckboxView>
      </CheckboxesView>
    )

    return isSmallScreen ? (
      <Content
        subtitle={<Checkboxes />}
        isSmallScreen={isSmallScreen}
        label={label ?? ''}
        title=""
      />
    ) : (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Checkboxes />
      </View>
    )
  }
})

const interestColumn = {
  id: 'interestColumn',
  header: 'curation:submissions:table:interest',
  headerStyle: { ...headerStyle, textAlign: 'center' },
  sort: {
    key: appSearchColumnMap['interestVotes'],
    defaultDir,
    isDefault: false
  },
  width: 10,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    let interest = `-`
    const submissionVotes = item?.submissionVotes ?? []
    const totalVotes = submissionVotes?.length ?? 0

    if (totalVotes > 0) {
      const interestVotes = submissionVotes.reduce(
        (acc, submissionVote) =>
          submissionVote.vote === SubmissionVotingVoteEnum.Shortlisted ? 1 : 0,
        0
      )
      if (interestVotes) {
        interest = `${interestVotes}`
      }
    }

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={interest}
      />
    )
  }
}

const memberFavoritesColumn = {
  id: 'favoritesColumn',
  header: 'curation:submissions:table:memberFavorites',
  headerStyle: { ...headerStyle },
  width: 20,
  emptyMessage: '',
  isEditable: false,
  sort: {
    key: appSearchColumnMap['favorites'],
    defaultDir: 'asc',
    isDefault: false
  },
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    let score = item?.submissionVotes?.filter(v => v.favorite)?.length || '-'
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={score}
      />
    )
  }
}

const shortlistedColumn = {
  id: 'shortlistedColumn',
  header: 'curation:submissions:table:shortlisted',
  headerStyle,
  width: 15,
  emptyMessage: '',
  isEditable: false,
  sort: {
    key: appSearchColumnMap['shortlisted'],
    defaultDir: 'asc',
    isDefault: false
  },
  sortContainerStyle: { justifyContent: 'center' },
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    let score =
      item?.submissionVotes?.filter(
        v => v.vote === SubmissionVotingVoteEnum.Shortlisted
      )?.length || '-'

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${score}`}
      />
    )
  }
}

const academyColumn = {
  id: 'academyColumn',
  header: 'curation:submissions:table:academy',
  headerStyle: { ...headerStyle, paddingTop: 1 },
  sort: {
    key: appSearchColumnMap['averageTotalAcademy'],
    defaultDir,
    isDefault: false
  },
  width: 10,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    let totalAcademy = item?.submissionAverage?.totalAcademy ?? '-'

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${totalAcademy}`}
      />
    )
  }
}

const commonColsMap = {
  selectItem: selectItemColumn,
  newSelectItem: newSelectItemColumn,
  company: companyColumn,
  product: productColumn,
  category: getCategoryColumn,
  status: statusColumn,
  wasColumn: wasColumn,
  favorite: memberFavoritesColumn,
  shortlist: shortlistedColumn
}

const sharedColumns = [companyColumn, productColumn, getCategoryColumn()]

const advisorSelectSubmissionHandler = (linkToScreen, cfsId, advisorId) => {
  linkToScreen('SubmissionsSelection', {
    cfsId,
    advisorId
  })
}

const advisorNameColumn = {
  id: 'advisorNameColumn',
  header: 'curation:advisors:table:name',
  headerStyle: { ...headerStyle, textAlign: 'start' },
  sort: {
    key: 'fullName',
    defaultDir,
    isDefault: false
  },
  width: 20,
  isEditable: false,
  role: null,
  fixed: true,
  link: '',
  Cell: ({ item, label, isSmallScreen, ...rest }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const linkToScreen = useLinkToScreen()
    const labelTranslated = label ? t(label) : ''
    const routeName = rest['link']

    const onPress = () => {
      if (routeName) {
        linkToScreen(routeName, {
          advisorId: item?.id,
          id: item?.callForSubmissionId
        })
      }
    }

    const titleObj = {
      title: item.fullName,
      subtitle: undefined
    }

    if (!routeName) {
      return (
        <Content
          isSmallScreen={isSmallScreen}
          label={labelTranslated}
          title={item.fullName}
        />
      )
    }
    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={titleObj.title}
        subtitle={undefined}
        onPress={onPress}
        isValueHighlighted
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell item={titleObj} onPress={onPress} />
    )
  }
}

const advisorCFSSettingsNameColumn = {
  id: 'advisorNameColumn',
  header: 'curation:advisors:table:name',
  sort: {
    key: 'fullName',
    defaultDir,
    isDefault: false
  },
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 20,
  isEditable: false,
  role: null,
  fixed: true,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const linkToScreen = useLinkToScreen()
    const labelTranslated = label ? t(label) : ''
    const isUnsaved = item?.isUnSaved

    const onPress = () => {
      if (!isUnsaved) {
        advisorSelectSubmissionHandler(
          linkToScreen,
          item?.callForSubmissionId,
          item?.id
        )
      }
    }
    const titleObj = {
      title: `${item.fullName}${
        isUnsaved ? ` (${t('curation:advisors:status:unsaved')})` : ''
      }`,
      subtitle: undefined
    }

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={titleObj.title}
        subtitle={undefined}
        onPress={onPress}
        isValueHighlighted={!isUnsaved}
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell
        item={titleObj}
        onPress={onPress}
        isValueHighlighted={!isUnsaved}
      />
    )
  }
}

const rolesColumn = {
  id: 'rolesColumn',
  header: 'curation:advisors:table:roles',
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 10,
  emptyMessage: '',
  isEditable: false,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const roles = item?.roles?.join(',\n')

    const labelTranslated = label ? t(label) : ''
    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={roles}
      />
    )
  }
}

const advisorEmailColumn = {
  id: 'advisorEmailColumn',
  header: 'curation:advisors:table:email',
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''
    const email =
      item?.person?.emailAddresses[0]?.email ??
      t('curation:advisors:table:noEmail')

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={email}
      />
    )
  }
}

const cfsSubmissionsCountColumn = {
  id: 'cfsSubmissionsCountColumn',
  header: 'curation:advisors:table:cfsSubmissions',
  headerStyle,
  sort: {
    key: 'cfsSubmissionReviews',
    defaultDir,
    isDefault: false
  },
  width: 18,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''
    const linkToScreen = useLinkToScreen()
    const isUnsaved = item?.isUnSaved

    const onPress = () => {
      if (!isUnsaved) {
        advisorSelectSubmissionHandler(
          linkToScreen,
          item?.callForSubmissionId,
          item?.id
        )
      }
    }
    const titleObj = {
      title: item?.cfsSubmissionReviews?.length,
      subtitle: undefined
    }

    if (item?.isUnSaved) {
      return null
    }

    return isSmallScreen ? (
      <LabelAndTitleCell
        label={labelTranslated}
        title={titleObj.title}
        subtitle={undefined}
        onPress={onPress}
        isValueHighlighted={!isUnsaved}
        containerStyles={{
          borderTopWidth: 0
        }}
      />
    ) : (
      <TitleCell
        item={titleObj}
        onPress={onPress}
        isValueHighlighted={!isUnsaved}
        style={{ textAlign: 'center' }}
      />
    )
  }
}

const newAdvisorsSubmissionsStatusColumn = {
  id: 'advisorsSubmissionsStatusColumn',
  header: 'curation:advisors:table:cfsStatus',
  headerStyle,
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${item?.advisorReviews} / ${item?.cfsSubmissionReviews}`}
      />
    )
  }
}

const advisorsSubmissionsStatusColumn = {
  id: 'advisorsSubmissionsStatusColumn',
  header: 'curation:advisors:table:cfsStatus',
  headerStyle,
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={`${(item?.advisorReviews ?? []).length} / ${
          (item?.cfsSubmissionReviews ?? []).length
        }`}
      />
    )
  }
}

const cfsSubmissionsColumn = {
  id: 'cfsSubmissionsColumn',
  header: 'callsForSubmission:table:submissions',
  headerStyle,
  width: 10,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={(item?.cfsSubmissionReviews ?? []).length || '-'}
      />
    )
  }
}

const newCfsSubmissionsColumn = {
  id: 'cfsSubmissionsColumn',
  header: 'callsForSubmission:table:submissions',
  headerStyle,
  sort: {
    key: 'cfsSubmissionReviews',
    defaultDir,
    isDefault: false
  },
  width: 10,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={item?.cfsSubmissionReviews || '-'}
      />
    )
  }
}

const advisorSubmissionsFavoritesColumn = {
  id: 'advisorSubmissionsFavoritesColumn',
  header: 'callsForSubmission:table:submissionsFavoritesCount',
  headerStyle,
  sort: {
    key: 'submissionsFavoritesCount',
    defaultDir,
    isDefault: false
  },
  width: 10,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={item?.submissionsFavoritesCount}
      />
    )
  }
}

const advisorSubmissionsAcademyCountColumn = {
  id: 'advisorSubmissionsAcademyCountColumn',
  header: 'curation:advisors:table:academyCount',
  headerStyle,
  sort: {
    key: 'academyAnswersCount',
    defaultDir,
    isDefault: false
  },
  width: 10,
  isEditable: false,
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        style={{ textAlign: 'center' }}
        label={labelTranslated}
        isSmallScreen={isSmallScreen}
        title={item.academyAnswersCount}
      />
    )
  }
}

const submissionsCountColumn = {
  id: 'cfsSubmissionsCountColumn',
  header: 'curation:advisors:table:submissions',
  headerStyle,
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item, label, isSmallScreen }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const labelTranslated = label ? t(label) : ''

    return (
      <Content
        isSmallScreen={isSmallScreen}
        label={labelTranslated}
        title={item?.submissionsCount}
        style={{ textAlign: 'center' }}
      />
    )
  }
}

const dropDownIndicatorColumn = {
  id: 'dropDownIndicatorColumnId',
  header: '',
  headerStyle: { ...headerStyle, textAlign: 'start' },
  width: 5,
  emptyMessage: '',
  isEditable: false,
  Cell: ({
    item,
    handleDetailedViewItems,
    isSmallScreen
  }: CellRendererProps<any>) => {
    const [rotateIcon, setRotateIcon] = useState<boolean>(false)
    return isSmallScreen ? null : (
      <TouchableOpacity
        style={{ alignItems: 'center' }}
        onPress={() => {
          handleDetailedViewItems && handleDetailedViewItems(item?.id)
          setRotateIcon(!rotateIcon)
        }}
      >
        <Icon
          style={{
            ...(rotateIcon ? { transform: [{ rotate: '180deg' }] } : {})
          }}
          name="dropdownArrow"
          width={41}
          height={22}
          color={Theme.colors.dropDownIndicatorColor}
        />
      </TouchableOpacity>
    )
  }
}

const commonCols = ['selectItem', 'company', 'product', 'category']
const newCommonCols = ['newSelectItem', 'company', 'product', 'category']

const getCommonCols = (cols, decoratorsProps = {}) =>
  cols.map(colName => {
    const column =
      typeof commonColsMap[colName] === 'function'
        ? commonColsMap[colName](decoratorsProps[colName])
        : commonColsMap[colName]
    return {
      ...column,
      ...(decoratorsProps[colName] || {})
    }
  })

const columnsRoleMap = {
  [RoleEnum.InnovationAdvisor]: ({ cfsId, advisorUserId }) => [
    ...getCommonCols(commonCols, {
      product: {
        role: RoleEnum.InnovationAdvisor
      },
      category: {
        cfsId,
        advisorUserId
      }
    })
  ],
  [RoleEnum.InnovationStaff]: ({ cfsId, advisorUserId }) => [
    ...getCommonCols(commonCols, {
      product: {
        role: RoleEnum.InnovationStaff
      },
      category: {
        cfsId,
        advisorUserId
      }
    }),
    contactsColumn
  ]
}

const DEBOUNCE_MUTATION_MILLISEC = 500

const getCohortSelectionColumn = group => ({
  id: 'getCohortSelectionColumn',
  width: 20,
  header: group.shortName,
  headerStyle: {
    ...headerStyle,
    textAlign: 'center'
  },
  emptyMessage: '',
  isEditable: false,
  cellStyle: { margin: '0px 0px 3px 0px;' },
  subtitle: [
    { id: 'cohortPass', label: 'curation:cohort:table:pass' },
    { id: 'cohortCut', label: 'curation:cohort:table:cut' }
  ],
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const {
      colors: { primaryPalette, success, danger }
    } = useTheme()
    const timeoutHandle = React.useRef<any>()
    const buttonInteractions = React.useRef<number>(0)

    const currentCFSStep = item?.callForSubmission?.currentStep?.name

    const cohort = item?.cohorts?.length
      ? item.cohorts?.find(cohort => cohort?.group?.id === group.id)
      : null

    const hasPresentedBefore = item?.cohortsHistory?.length
      ? item.cohortsHistory.some(({ id }) => id === group.id)
      : false

    const passSubmission = hasPresentedBefore || !cohort?.deletedById

    // Edit state to chose the correct option between pass and cut
    const initial = cohort ? passSubmission : null
    const [selected, setSelected] = React.useState<boolean | null>(initial)

    const {
      upsertSubmissionGroupMap,
      loading: mutationLoading
    } = useSelectSubmissionForCohortMutation()

    // A delay is added for the staff to choose the most suitable option
    React.useEffect(() => {
      if (timeoutHandle.current) {
        clearTimeout(timeoutHandle.current)
      }
      timeoutHandle.current = setTimeout(async () => {
        if (selected !== initial) {
          await upsertSubmissionGroupMap({
            variables: {
              group,
              submissionId: item.id,
              isDeleted: !selected
            }
          })
          buttonInteractions.current = 0
        }
      }, DEBOUNCE_MUTATION_MILLISEC)
    }, [selected])

    const onPressToggle = (isDeleted = false) => {
      ++buttonInteractions.current
      setSelected(!isDeleted)
    }

    /* If the interactions with the button are greater than 1 it means that
    there is an undecided behavior, once the time to choose is taken the
    ratio buttons are disabled until the last captured state is saved. */
    const disableButtons = buttonInteractions.current > 1 && mutationLoading

    // Checked color
    const checkboxColor = hasPresentedBefore
      ? primaryPalette.alert
      : selected === null
      ? undefined
      : selected
      ? success
      : danger

    const Checkboxes = () => (
      <CheckboxesView>
        <CheckboxView>
          <CohortCheckBox
            group={group}
            submission={item}
            isDisabled={
              currentCFSStep !== CallForSubmissionStepEnum.cohort ||
              hasPresentedBefore ||
              disableButtons
            }
            isSelected={selected !== null ? selected : false}
            checkboxColor={selected ? checkboxColor : undefined}
            onPressToggle={() => onPressToggle()}
          />
        </CheckboxView>
        <CheckboxView>
          <CohortCheckBox
            group={group}
            submission={item}
            isDisabled={
              currentCFSStep !== CallForSubmissionStepEnum.cohort ||
              hasPresentedBefore ||
              disableButtons
            }
            isDeleted
            isSelected={selected !== null ? !selected : false}
            checkboxColor={!selected ? checkboxColor : undefined}
            onPressToggle={() => onPressToggle(true)}
          />
        </CheckboxView>
      </CheckboxesView>
    )

    return isSmallScreen ? (
      <Content
        subtitle={<Checkboxes />}
        isSmallScreen={isSmallScreen}
        label={label as any}
        title=""
      />
    ) : (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Checkboxes />
      </View>
    )
  }
})

const newGetCohortSelectionColumn = ({
  group,
  handleUpdateCohortSelection
}) => ({
  id: 'getCohortSelectionColumn',
  width: 7.5,
  header: group.shortName,
  headerStyle: {
    ...headerStyle,
    textAlign: 'center'
  },
  emptyMessage: '',
  isEditable: false,
  cellStyle: { margin: '0px 0px 3px 0px;' },
  subtitle: [{ id: 'cohortPass', label: 'curation:cohort:table:pass' }],
  Cell: ({ item, isSmallScreen, label }: CellRendererProps<any>) => {
    const [isLoadingUpsert, setIsLoadingUpert] = useState(false)
    const cohort: GroupSelection | undefined =
      item?.cohorts?.find(coh => coh?.group?.id === group?.id) ?? undefined
    const currentStepName = item?.callForSubmission?.currentStep?.name
    const hasPresentedBefore = cohort ? cohort.hasPresentedBefore : false
    const pass = hasPresentedBefore || cohort ? !cohort?.deletedById : undefined
    const cut = cohort?.deletedById
      ? !hasPresentedBefore && !!cohort?.deletedById
      : undefined

    const [isPassSelected, setIsPassSelected] = useState<boolean | undefined>(
      pass || typeof cut !== 'undefined' ? !cut : undefined
    )

    const isCutSelectedValue = useMemo(
      () => (typeof isPassSelected !== 'undefined' ? !isPassSelected : false),
      [isPassSelected]
    )
    const isPassSelectedValue = useMemo(() => isPassSelected ?? false, [
      isPassSelected
    ])

    const onToggle = useCallback(
      async (isDeleted: boolean, isSelected?) => {
        setIsLoadingUpert(true)
        const result = await handleUpdateCohortSelection({
          group,
          submissionId: item?.id,
          isDeleted: isDeleted,
          isSelected
        })
        if (!result) {
          if (isPassSelected === true && isSelected === true) {
            setIsPassSelected(false)
          } else {
            setIsPassSelected(true)
          }
        }
        setIsLoadingUpert(false)
      },
      [
        group,
        item?.id,
        isPassSelectedValue,
        isCutSelectedValue,
        handleUpdateCohortSelection
      ]
    )

    const isDisabled =
      currentStepName !== CallForSubmissionStepEnum.cohort ||
      hasPresentedBefore ||
      isLoadingUpsert

    const checkboxColor = hasPresentedBefore
      ? Theme.colors.primaryPalette.alert
      : isPassSelectedValue
      ? Theme.colors.success
      : isCutSelectedValue
      ? Theme.colors.danger
      : undefined

    const checkBoxProps = {
      isDisabled,
      onToggle: onToggle,
      isLoading: isLoadingUpsert
    }

    const Checkboxes = () => (
      <CheckboxesView>
        <CheckboxView>
          <NewCohortCheckBox
            {...checkBoxProps}
            isSelected={isPassSelectedValue}
            checkboxColor={isPassSelectedValue ? checkboxColor : undefined}
          />
        </CheckboxView>
      </CheckboxesView>
    )

    return isSmallScreen ? (
      <Content
        subtitle={<Checkboxes />}
        isSmallScreen={isSmallScreen}
        label={label ?? ''}
        title=""
      />
    ) : (
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Checkboxes />
      </View>
    )
  }
})

const getAdvisorItemStatus = (item, userId, t) => {
  const hasAdvised =
    item?.advisorsReviews?.find(
      review =>
        review.advisorId === userId &&
        review.submissionId === item.id &&
        review?.answers.length
    ) || false

  return t(
    hasAdvised
      ? 'curation:advisors:status:advised'
      : 'curation:advisors:status:pending'
  )
}

const hasUserAdvised = (submission, userId): boolean => {
  return !!submission?.advisorsReviews?.find(
    review =>
      review.advisorId === userId &&
      review.submissionId === submission.id &&
      review?.answers.length
  )
}

const advisorStatusColumn = {
  id: 'advisorStatusColumn',
  header: 'curation:submissions:table:status',
  headerStyle,
  width: 15,
  isEditable: false,
  role: null,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const { colors } = useTheme()
    const { currentUserId } = useCurrentUser()

    const hasAdvised = hasUserAdvised(item, currentUserId)

    const titleLabel = getAdvisorItemStatus(item, currentUserId, t)
    const textStyles = hasAdvised
      ? { color: colors.success, textAlign: 'center' }
      : { fontStyle: 'italic', textAlign: 'center' }

    return <Text styles={textStyles}>{titleLabel}</Text>
  }
}

const newAdvisorStatusColumn = (
  totalAdvised = 0,
  totalSubmission = 0,
  advisorId = '',
  t
) => ({
  id: 'advisorStatusColumn',
  header: 'curation:submissions:table:status',
  headerStyle,
  width: 15,
  filter: {
    type: 'filter',
    key: appSearchColumnMap['advisorsWhoAdvised'],
    isFilterArray: true,
    isHook: false,
    options: advisorStatusFilterOptions(t),
    handleChange: (selection, setQueryFilters) => {
      const value = selection[appSearchColumnMap['advisorsWhoAdvised']]
      if (value) {
        setQueryFilters(advisorStatusFilters[value](advisorId))
      } else {
        // clear filters
        setQueryFilters(advisorStatusFilters.clear)
      }
    },
    getValue: filters => {
      if (filters['advisors_who_advised']) {
        return 'advised'
      }
      return ''
    },
    defaultValue: ''
  },
  isEditable: false,
  role: null,
  subtitle: [
    {
      id: 'totalIndicators',
      label: function (context) {
        const { totalElements, queryFilters, data } = context
        const filteredTotalAdvised =
          data?.filter(result => result.advisorSubmissionReview?.hasAdvised)
            ?.length | 0
        const isNoneFilterSet = (filterKey: string): boolean =>
          queryFilters?.none?.length &&
          queryFilters?.none.some(noneFilter => !!noneFilter?.[filterKey])

        const areFiltersPresent =
          queryFilters?.category?.length ||
          queryFilters?.advisors_who_advised?.length ||
          queryFilters?.advisors_who_set_academy?.length ||
          queryFilters?.advisors_who_set_favorite?.length ||
          isNoneFilterSet('advisors_who_advised') ||
          isNoneFilterSet('advisors_who_set_academy') ||
          isNoneFilterSet('advisors_who_set_favorite')
        if (areFiltersPresent) {
          return `${
            totalElements ? filteredTotalAdvised : 0
          } / ${totalElements}`
        }
        return `${totalAdvised} / ${totalSubmission}`
      }
    }
  ],
  Cell: ({ item }: CellRendererProps<any>) => {
    const { t } = useTranslation()

    const hasAdvised = item?.advisorSubmissionReview?.hasAdvised ?? false

    const titleLabel = t(
      hasAdvised
        ? 'curation:advisors:status:advised'
        : 'curation:advisors:status:pending'
    )
    const textStyles = hasAdvised
      ? { color: Theme.colors.success, textAlign: 'center' }
      : { fontStyle: 'italic', textAlign: 'center' }

    return <Text styles={textStyles}>{titleLabel}</Text>
  }
})

export const CURATION_SUBMISSIONS_TABLE_CONFIG: Partial<TableConfig<any>> = {
  name: 'CurationSubmissionsTable',
  headerContainerStyle,
  LargeRow: DynamicAlternatingHoverableRow,
  SmallRow: SmallRow
}

export const getQueryFiltersByCFS = (
  cfsId: string,
  categoryFilteredBy?: string[],
  statusFilteredBy?: string,
  isTestingModule?: boolean
): any => {
  let filters = {
    all: [
      {
        [appSearchColumnMap['callForSubmissionId']]: cfsId
      },
      categoryFilteredBy?.length
        ? { [appSearchColumnMap['category']]: categoryFilteredBy }
        : {},
      statusFilteredBy
        ? { [appSearchColumnMap['hasBeenSubmitted']]: statusFilteredBy }
        : {}
    ]
  }

  if (isTestingModule) {
    filters['none'] = [
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.advising
      },
      {
        [appSearchColumnMap['submissionStep']]: CallForSubmissionStepEnum.cohort
      },
      {
        [appSearchColumnMap['submissionStep']]: CallForSubmissionStepEnum.voting
      },
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.finalSelections
      },
      {
        [appSearchColumnMap['submissionStep']]: CallForSubmissionStepEnum.event
      }
    ]
  }

  return filters
}

export const commonFixedColumns = [
  'selectionItemColumn',
  'getPresentationSelectionColumn',
  'adviseColumn',
  'companyColumn',
  'productColumn'
]

export interface getTableConfigArgs {
  role: RoleEnum
  isAdmin?: Boolean
  engine: EngineEnum | undefined
  currentCFSStep?: CallForSubmissionStepEnum | null
  cfsId: string
  isInnovationNewDynamicTable?: boolean
  categoryFilteredBy?: string[]
  statusFilteredBy?: string
  canEditAll?: boolean
  passOrCutText?: string | undefined
  category?: string | undefined
  isTestingModule?: boolean
}

export const getSubmissionTableConfig = (args: getTableConfigArgs) => {
  const {
    cfsId,
    role,
    engine,
    isInnovationNewDynamicTable,
    canEditAll,
    statusFilteredBy,
    categoryFilteredBy,
    isTestingModule
  } = args
  const table = Object.assign(
    {},
    CURATION_SUBMISSIONS_TABLE_CONFIG
  ) as TableConfig<any>
  table.queryDynamicName = 'submissionsSearchInnovationEngine'
  table.name = TableNameEnum.Submission
  table.isSticky = true

  let columns = [
    ...getCommonCols(isInnovationNewDynamicTable ? newCommonCols : commonCols, {
      product: {
        role: RoleEnum.InnovationStaff
      }
    }),
    {
      ...statusColumn,
      ...{ role: RoleEnum.InnovationStaff }
    },
    role === RoleEnum.InnovationStaff && contactsColumn,
    submissionsEditOrViewByAdminColumn(canEditAll, table.name)
  ]

  table.columns = columns.map(column => ({
    ...column,
    fixed: !!commonFixedColumns.filter(
      fixedColumn => fixedColumn === column?.id
    ).length
  }))

  table.enableFilters = true
  table.skipCache = true
  table.engine = engine
  table.hitKey = getEngineHitKey(engine)
  table.queryConditions = {
    withSubmissionVotes: role !== RoleEnum.InnovationAdvisor
  }

  table.filters = getQueryFiltersByCFS(
    cfsId,
    categoryFilteredBy,
    statusFilteredBy,
    isTestingModule
  )
  table.showPDFButton = false
  table.showCsvButton = true
  table.customQueryFields = InnovationSubmissionFieldsFragment
  table.showTotalElements = true

  table.csvTypesMap = [
    {
      name: CSVTypeEnum.Submission,
      customCsv: false
    },
    {
      name: CSVTypeEnum.SubmissionWithResponses,
      customCsv: false
    }
  ]

  return table
}

const setNoneFilterByPassOrCutText = (
  passOrCutText: string | undefined
): any[] => {
  if (passOrCutText && passOrCutText === 'Pass') {
    return [
      {
        [appSearchColumnMap['hasBeenFastPassed']]: true
      }
    ]
  }
  return []
}

export const getPreCurationTableConfig = (args: getTableConfigArgs) => {
  const {
    role,
    currentCFSStep,
    cfsId,
    engine,
    canEditAll,
    isInnovationNewDynamicTable,
    passOrCutText,
    categoryFilteredBy,
    isTestingModule
  } = args
  const table = Object.assign({}, CURATION_SUBMISSIONS_TABLE_CONFIG)
  table.queryDynamicName = 'preCurationSearchInnovationEngine'
  table.name = TableNameEnum.PreCuration
  table.isSticky = true

  const preCurationColumns = columnsRoleMap[role]({})

  const showAdviseColumn =
    currentCFSStep !== CallForSubmissionStepEnum.submission &&
    role === RoleEnum.InnovationStaff

  let columns: any[] = [...preCurationColumns]

  if (!canEditAll) {
    columns = [
      ...columns,
      submissionsEditOrViewByAdminColumn(canEditAll, table.name)
    ]
  }

  if (isInnovationNewDynamicTable) {
    columns = columns.map(col => {
      if (col?.id === 'selectionItemColumn') {
        return newSelectItemColumn
      } else {
        return col
      }
    })
  }

  if (showAdviseColumn) {
    columns.splice(1, 0, adviseColumn)
  }

  table.columns = columns.map(column => ({
    ...column,
    fixed: !!commonFixedColumns.filter(
      fixedColumn => fixedColumn === column?.id
    ).length
  }))
  table.enableFilters = true
  table.fetchingDataOnFocus = false
  table.fetchPolicy = 'cache-and-network'
  table.engine = engine
  table.hitKey = getEngineHitKey(engine)
  const baseFilters = getQueryFiltersByCFS(cfsId)
  const filters: AppSearchFiltersInput = {
    all: [
      { [appSearchColumnMap['hasBeenSubmitted']]: 'true' },
      { [appSearchColumnMap['passOrCutText']]: passOrCutText },
      categoryFilteredBy?.length
        ? { [appSearchColumnMap['category']]: categoryFilteredBy }
        : {}
    ],
    none: isTestingModule ? setNoneFilterByPassOrCutText(passOrCutText) : []
  }

  table.showCsvButton = true
  table.showPDFButton = true
  table.customQueryFields = InnovationSubmissionFieldsFragment
  table.filters = mergeFilters(baseFilters, filters)
  table.showTotalElements = true

  table.csvTypesMap = [
    {
      name: CSVTypeEnum.PreCurate,
      customCsv: false
    },
    {
      name: CSVTypeEnum.PreCurateWithFeedback,
      customCsv: false
    }
  ]

  return table
}

export const getAdvisorsCurationTableConfig = (
  isInnovationNewDynamicTable?: boolean
) => {
  const advisorsColumns = [
    isInnovationNewDynamicTable ? newSelectItemColumn : selectItemColumn,
    { ...advisorNameColumn, link: 'CurationAdvisorDetail' },
    isInnovationNewDynamicTable
      ? newCfsSubmissionsColumn
      : cfsSubmissionsColumn,
    advisorSubmissionsFavoritesColumn,
    advisorSubmissionsAcademyCountColumn,
    isInnovationNewDynamicTable
      ? newAdvisorsSubmissionsStatusColumn
      : advisorsSubmissionsStatusColumn
  ]

  const table: TableConfig<any> = {
    name: 'advisors-curation-table',
    headerContainerStyle,
    LargeRow: DynamicAlternatingHoverableRow,
    SmallRow: SmallRow,
    columns: advisorsColumns.map(column => ({
      ...column
    })) as any[],
    enableFilters: false,
    showSearchField: false,
    showCsvButton: true,
    showPDFButton: false,
    showTotalElements: true
  } as any

  return table
}

export const getAdvisorsProgressTableConfig = (args: {
  engine: EngineEnum | undefined
  cfsId: string
  advisorId: string
  favoriteQuestionId: string
  submissionIdsToReview?: string[]
  academyQuestionId: string
  isInnovationNewDynamicTable?: boolean
}) => {
  const {
    cfsId,
    engine,
    advisorId,
    favoriteQuestionId,
    submissionIdsToReview,
    academyQuestionId,
    isInnovationNewDynamicTable
  } = args
  const table = Object.assign({}, CURATION_SUBMISSIONS_TABLE_CONFIG)
  table.queryDynamicName = 'submissionsSearchInnovationEngine'
  table.name = 'advisor-submission-status-table'

  const columns = [
    isInnovationNewDynamicTable ? newSelectItemColumn : selectItemColumn,
    companyColumn,
    productColumn,
    {
      ...(isInnovationNewDynamicTable
        ? newIsAdvisorFavoritedColumn
        : isAdvisorFavoritedColumn),
      favoriteQuestionId
    },
    {
      ...(isInnovationNewDynamicTable
        ? newIsAdvisorCheckedAcademyColumn
        : isAdvisorCheckedAcademyColumn),
      academyQuestionId
    },
    isInnovationNewDynamicTable
      ? newHasAdvisorStartedColumn
      : hasAdvisorStartedColumn
  ]

  table.columns = columns.map(column => ({
    ...column,
    fixed: !!commonFixedColumns.filter(
      fixedColumn => fixedColumn === column?.id
    ).length,
    advisorId
  })) as any[]

  table.enableFilters = true
  table.showPDFButton = false
  table.engine = engine
  table.isSticky = true
  table.hitKey = getEngineHitKey(engine)
  table.queryConditions = {
    withSubmissionVotes: true
  }

  const baseFilters = getQueryFiltersByCFS(cfsId)
  const filters: AppSearchFiltersInput = {
    all: [],
    none: [],
    any: []
  }
  // JC: We only want the submissions that have been assigned to each advisor
  if (submissionIdsToReview) {
    // @ts-ignore
    filters.any.push({ [appSearchColumnMap['id']]: submissionIdsToReview })
  }

  table.filters = mergeFilters(baseFilters, filters)
  table.customQueryFields = InnovationSubmissionAdviseFieldsFragment(advisorId)

  table.showTotalElements = true

  return table
}

interface getAdvisorTableConfigArgs extends getTableConfigArgs {
  t
  submissionIdsToReview?: string[]
  totalAdvised?: number
  advisorId?: string
}

const favoriteAcademyCheckColumn = (
  type: 'favorite' | 'academy' = 'favorite',
  advisorId: string
) => {
  const key =
    appSearchColumnMap[
      type === 'favorite' ? 'advisorsWhoSetFavorite' : 'advisorsWhoSetAcademy'
    ]
  return {
    id: type === 'favorite' ? 'favoriteCheckColumn' : 'academyCheckColumn',
    header: type === 'favorite' ? 'Favorite' : 'Academy',
    headerStyle: { ...headerStyle, textAlign: 'center' },
    width: 8,
    emptyMessage: '',
    isEditable: false,
    filter: {
      type: 'filter',
      key:
        appSearchColumnMap[
          type === 'favorite'
            ? 'advisorsWhoSetFavorite'
            : 'advisorsWhoSetAcademy'
        ],
      isFilterArray: true,
      isHook: false,
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ],
      handleChange: (selection, setQueryFilters) => {
        setQueryFilters(
          { [key]: [advisorId] },
          selection[key] === 'yes'
            ? FilterActionsEnum.SET_ROOT
            : selection[key] === 'no'
            ? FilterActionsEnum.SET_NONE
            : FilterActionsEnum.CLEAR
        )
      },
      defaultValue: ''
    },
    Cell: ({ item }: CellRendererProps<any>) => {
      const { colors } = useTheme()
      const isChecked =
        type === 'favorite'
          ? item?.advisorSubmissionReview.favorite
          : item?.advisorSubmissionReview.academy

      return (
        <CheckboxView>
          <Checkbox
            checked={isChecked}
            onPress={() => {}}
            isReadOnly
            flexContainerStyles={{
              paddingLeft: 0,
              marginBottom: 0
            }}
            checkedColor={colors.text}
            uncheckedColor={colors.text}
            checkedIconName=""
          />
        </CheckboxView>
      )
    }
  }
}

export const getAdvisingTableConfig = (args: getAdvisorTableConfigArgs) => {
  const {
    role,
    currentCFSStep,
    cfsId,
    engine,
    totalAdvised = 0,
    submissionIdsToReview,
    isInnovationNewDynamicTable,
    advisorId,
    t
  } = args

  const totalSubmission = submissionIdsToReview?.length || 0

  const table = Object.assign({}, CURATION_SUBMISSIONS_TABLE_CONFIG)
  table.queryDynamicName = 'advisingSearchInnovationEngine'
  table.name = TableNameEnum.Advising
  table.skipCache = true
  table.isSticky = true

  let columns: any[] = [
    ...columnsRoleMap[role]({ cfsId, advisorUserId: advisorId }),
    favoriteAcademyCheckColumn('favorite', advisorId as string),
    favoriteAcademyCheckColumn('academy', advisorId as string),
    isInnovationNewDynamicTable
      ? newAdvisorStatusColumn(totalAdvised, totalSubmission, advisorId, t)
      : advisorStatusColumn
  ]

  table.columns = columns.map(column => ({
    ...column,
    fixed: !!commonFixedColumns.filter(
      fixedColumn => fixedColumn === column?.id
    ).length
  }))

  if (isInnovationNewDynamicTable) {
    table.columns = table.columns.map(col => {
      if (col?.id === 'selectionItemColumn') {
        return newSelectItemColumn
      } else {
        return col
      }
    })
  }

  table.enableFilters = true
  table.isAlternativeView = false
  // if user dont has assigned submissions, then no case to query submissions
  if (submissionIdsToReview?.length) {
    table.engine = engine
  }
  if (isInnovationNewDynamicTable) {
    table.engine = engine
  }
  table.hitKey = getEngineHitKey(engine)
  const baseFilters = getQueryFiltersByCFS(cfsId)
  const filters: AppSearchFiltersInput = {
    all: [],
    none: [],
    any: []
  }

  if (
    currentCFSStep === CallForSubmissionStepEnum.advising &&
    filters.none &&
    filters.all
  ) {
    filters.none.push({
      [appSearchColumnMap['submissionStep']]:
        CallForSubmissionStepEnum.submission
    })

    filters.none.push({
      [appSearchColumnMap['submissionStep']]:
        CallForSubmissionStepEnum.preCurate
    })

    filters.none.push({ [appSearchColumnMap['hasBeenFastPassed']]: 'true' })
  }

  if (!filters.none) {
    filters.none = []
  }

  filters.none.push({
    [appSearchColumnMap['submissionStatus']]:
      InnovationSubmissionStatusEnum.rejected
  })
  filters.none.push({
    [appSearchColumnMap['submissionStatus']]:
      InnovationSubmissionStatusEnum.fastPassed
  })
  filters.none.push({
    [appSearchColumnMap['submissionStep']]: CallForSubmissionStepEnum.submission
  })
  filters.none.push({
    [appSearchColumnMap['submissionStep']]: CallForSubmissionStepEnum.preCurate
  })

  filters.none.push({ [appSearchColumnMap['hasBeenFastPassed']]: 'true' })

  // JC: We only want the submissions that have been assigned to each advisor
  if (submissionIdsToReview) {
    // @ts-ignore
    filters.any.push({ [appSearchColumnMap['id']]: submissionIdsToReview })
  }

  table.filters = mergeFilters(baseFilters, filters)
  table.showCsvButton = true
  table.showPDFButton = true
  table.customQueryFields = InnovationSubmissionAdviseFieldsFragment(advisorId)

  return table
}

interface VariablesProps {
  group: Group
  submissionId: string
  isDeleted: boolean
}

interface getCohortSubmissionTableConfigArgs extends getTableConfigArgs {
  cohortId?: string | undefined
  groupList: Group[]
  reviewQuestions: Question[]
  reviewFormQuestions?: Question[]
  advisorsUsers?: any[]
  callForSubmission?: CallForSubmission
  cohortPassOrCut?: string | undefined
  handleUpdateCohortSelection: (variables: VariablesProps) => Promise<boolean>
}

export const getCohortSubmissionTableConfig = (
  args: getCohortSubmissionTableConfigArgs
) => {
  const {
    groupList,
    cohortId,
    cfsId,
    engine,
    reviewQuestions,
    callForSubmission,
    isInnovationNewDynamicTable,
    cohortPassOrCut,
    handleUpdateCohortSelection
  } = args
  const reviewFormQuestions = callForSubmission?.reviewFormQuestions ?? []
  const advisorsUsers =
    (isInnovationNewDynamicTable
      ? callForSubmission?.advisorsInfo?.map(advisor => advisor?.user)
      : callForSubmission?.advisorsUsers) ?? []

  const cohortColumns = [
    isInnovationNewDynamicTable ? newSelectItemColumn : selectItemColumn,
    ...sharedColumns,
    wasColumn,
    shortlistedColumn,
    academyColumn,
    ...groupList.map(group => {
      if (isInnovationNewDynamicTable) {
        return newGetCohortSelectionColumn({
          group,
          handleUpdateCohortSelection
        })
      } else {
        return getCohortSelectionColumn(group)
      }
    }),
    dropDownIndicatorColumn
  ]

  const getCustomCsvColumnsAdvisoryRawData = (t: any) => {
    const customColumns: any = [
      { id: 'companyName', label: `"${t('curation:cohort:csv:companyName')}"` },
      {
        id: 'questionName',
        label: `"${t('curation:cohort:csv:questionName')}"`
      },
      ...advisorsUsers.map(({ id, person: { firstName, lastName } }) => ({
        id,
        label: `"${firstName} ${lastName}"`
      }))
    ]
    return customColumns.map(cc => cc.label)
  }
  const getCustomCsvDataAdvisoryRawData = (submission: any) => {
    const advisorsReviews = submission?.advisorsReviews ?? []
    const resultRow: any[] = []
    reviewFormQuestions.map(rq => {
      const resultReviewRow = [
        `"${submission?.company?.name}"`,
        `"${rq.questionText}"`
      ]
      advisorsUsers.map(adv => {
        const advisorReviewIndex = advisorsReviews.findIndex(
          ar => ar.advisorId === adv.id
        )
        if (advisorReviewIndex > -1) {
          const answers = advisorsReviews[advisorReviewIndex]?.answers ?? []
          const answerIndex =
            answers.findIndex(ans => ans.questionId === rq.id) ?? -1
          if (answerIndex > -1) {
            return resultReviewRow.push(
              `"${answers[answerIndex]?.value ?? ''}"`
            )
          } else {
            return resultReviewRow.push(`""`)
          }
        } else {
          return resultReviewRow.push(`""`)
        }
      })
      resultRow.push(resultReviewRow)
    })
    return resultRow.map(row => row.join(CSV_SEPARATOR_CHARACTER)).join('\n')
  }
  const getCustomCsvColumns = (data: any[]): [any[], string] => {
    let questionAnswersValidation = {}
    let questionAnswers: any[] = []
    data.map(submission => {
      const submissionAnswers = submission?.submissionAnswers ?? []
      submissionAnswers.map(sa => {
        if (!questionAnswersValidation[sa?.question?.id]) {
          questionAnswers.push({
            id: sa?.question?.id,
            name: sa?.question?.questionText
          })
          questionAnswersValidation[sa?.question?.id] = true
        }
      })
    })
    return [
      questionAnswers,
      [
        { name: 'Company Name' },
        { name: 'Product' },
        { name: 'Category' },
        { name: 'WASS' },
        { name: 'Advisor Favorites' },
        { name: 'Market Fit' },
        { name: 'Innovativeness' },
        { name: 'Fundamentals' },
        { name: 'Traction' },
        { name: 'Submission' },
        { name: 'Shortlisted' },
        { name: 'Academy?' },
        ...questionAnswers,
        ...groupList.map(gl => ({ name: gl.shortName }))
      ]
        .map(col => (col?.name ? `"${col?.name}"` : `"Unknown Question"`))
        .join(CSV_SEPARATOR_CHARACTER)
    ]
  }
  const getCustomCsvDataCohortSelectionWithResponses = (submissions: any[]) => {
    let submissionRows: string[] = []
    const [questionAnswers, csvHeaders] = getCustomCsvColumns(submissions)

    const getSubmissionScoreValue = (
      submissionScoreQuestions: any[] = [],
      questionText: string
    ): string => {
      const ssQuestion = submissionScoreQuestions?.filter(
        ss => ss?.question?.questionText === questionText
      )
      if (ssQuestion?.length) {
        return `"${ssQuestion[0]?.value ?? `-`}"`
      }
      return `"-"`
    }

    const getCohortSelection = (group: Group, submission: any): string => {
      const cohort: GroupSelection | undefined =
        submission?.cohorts?.find(coh => coh?.group?.id === group?.id) ??
        undefined
      const hasPresentedBefore = cohort ? cohort.hasPresentedBefore : false
      const pass = hasPresentedBefore || cohort ? !cohort?.deletedById : false
      const cut = cohort?.deletedById
        ? !hasPresentedBefore && !!cohort?.deletedById
        : false
      return pass ? 'Pass' : cut ? 'Cut' : '-'
    }

    submissions.map(submission => {
      const submissionAnswers = submission?.submissionAnswers ?? []
      const submissionRow: string[] = [
        `"${submission?.company?.name}"`,
        `"${submission?.submissionInfo?.name}"`,
        `"${submission?.submissionInfo?.categoryLabel}"`,
        `"${submission?.submissionAverage?.wass}"`,
        `"${submission?.submissionAverage?.totalFav}"`,
        getSubmissionScoreValue(
          submission?.submissionAverage?.submissionScores,
          'Market Fit'
        ),
        getSubmissionScoreValue(
          submission?.submissionAverage?.submissionScores,
          'Innovativeness'
        ),
        getSubmissionScoreValue(
          submission?.submissionAverage?.submissionScores,
          'Fundamentals'
        ),
        getSubmissionScoreValue(
          submission?.submissionAverage?.submissionScores,
          'Traction'
        ),
        getSubmissionScoreValue(
          submission?.submissionAverage?.submissionScores,
          'Submission'
        ),
        `"${
          submission?.submissionVotes?.filter(
            v => v.vote === SubmissionVotingVoteEnum.Shortlisted
          )?.length
        }"` ?? `"-"`,
        `"${submission?.submissionAverage?.totalAcademy}"`
      ]
      questionAnswers.map(qa => {
        const submissionQuestionAnswer = submissionAnswers.filter(
          sa => sa?.question?.id === qa?.id
        )
        if (submissionQuestionAnswer.length) {
          const value =
            typeof submissionQuestionAnswer[0]?.value === 'object'
              ? `-`
              : submissionQuestionAnswer[0]?.value
              ? String(submissionQuestionAnswer[0]?.value)
              : `-`

          submissionRow.push(
            `"${value
              ?.replace(/(\r\n|\n|\r)/gm, '') // Remove Line Breaks
              ?.replace(/[",]/gi, '')}"` // Remove quotes and commas
          )
        } else {
          submissionRow.push(`"-"`)
        }
      })
      groupList.map(gl => {
        const cohortSelection = getCohortSelection(gl, submission)
        submissionRow.push(`"${cohortSelection}"`)
      })
      submissionRows.push(submissionRow.join(CSV_SEPARATOR_CHARACTER))
    })
    return `${csvHeaders}\n${submissionRows.join('\n')}`
  }
  const csvTypesMap = [
    {
      name: CSVTypeEnum.CohortRawData,
      customCsv: true,
      getCustomCsvColumns: getCustomCsvColumnsAdvisoryRawData,
      getCustomCsvData: getCustomCsvDataAdvisoryRawData
    },
    {
      name: CSVTypeEnum.CohortSelectionTable,
      customCsv: false,
      excludedColumns: ['getCohortSelectionColumn']
    },
    {
      name: CSVTypeEnum.CohortWithResponses,
      customCsv: true,
      getCustomCsvBody: getCustomCsvDataCohortSelectionWithResponses
    },
    {
      name: CSVTypeEnum.CohortAssignmentDistributionList,
      customCsv: false
    }
  ]

  const customColumnWidth = {
    selectionItemColumn: 4,
    companyColumn: 12,
    productColumn: 15
  }

  const table: TableConfig<any> = {
    name: TableNameEnum.Cohort,
    queryDynamicName: 'cohortSearchInnovationEngine',
    headerContainerStyle,
    LargeRow: DynamicAlternatingHoverableRow,
    SmallRow: SmallRow,
    // @ts-ignore
    columns: cohortColumns.map((column, i) => ({
      ...column,
      fixed: i < 2,
      width: customColumnWidth[column.id] ?? column.width
    })),
    enableFilters: true,
    engine: engine,
    skipCache: true,
    hitKey: getEngineHitKey(engine),
    csvTypesMap,
    isSticky: true,
    getQuestionAndAnswers: true,
    cohortId
  }

  table.queryConditions = {
    withSubmissionVotes: true
  }

  const baseFilters = getQueryFiltersByCFS(cfsId)
  const filters: AppSearchFiltersInput = {
    all: [
      { withDeleted: true },
      { [appSearchColumnMap['hasBeenSubmitted']]: 'true' },
      cohortPassOrCut === 'PASS'
        ? {
            [appSearchColumnMap['cohortPassGroupId']]: groupList.map(
              group => group.id
            )
          }
        : cohortPassOrCut === 'CUT'
        ? {
            [appSearchColumnMap['cohortCutGroupId']]: groupList.map(
              group => group.id
            )
          }
        : {}
    ],
    none: [
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.submission
      },
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.preCurate
      }
    ]
  }

  const detailItem = (
    title: string,
    value: string,
    leftTable,
    key,
    width = '',
    margin = 'auto'
  ) => (
    <DetailItemView
      leftTable={leftTable}
      key={key}
      width={width}
      margin={margin}
    >
      <Text
        styles={{ fontWeight: 'bold', textAlign: 'right' }}
      >{`${title}: `}</Text>
      <Text> {value} </Text>
    </DetailItemView>
  )

  table.getDetailedViewComponents = props => {
    const { item, leftTable } = props

    const advisorFavorites = item?.submissionAverage?.totalFav || '-'

    const questions = reviewQuestions.map((question, i) => {
      const score = getReviewScoreByQuestionId(item, question.id)
      return detailItem(question.questionText, score, leftTable, i, 'auto')
    })

    return leftTable
      ? [
          detailItem(
            'Advisor favorites',
            advisorFavorites,
            leftTable,
            'adv-fav-item',
            '100%',
            '0 0 0 6px'
          ),
          ...questions
        ]
      : []
  }

  table.showCsvButton = true
  table.showPDFButton = true
  table.filters = mergeFilters(baseFilters, filters)
  table.customQueryFields = InnovationSubmissionCohortFieldsFragment
  table.showTotalElements = true

  return table
}

const actionsColumn = {
  id: 'actionsColumn',
  headerStyle,
  width: 7,
  isEditable: false,
  role: null,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { t } = useTranslation()
    const { colors } = useTheme()
    const [isVisible, setIsVisible] = useState(false)

    if (item?.isUnSaved) {
      return null
    }

    return (
      <View>
        <TouchableIcon
          width={20}
          height={20}
          disabled={false}
          name="resend"
          color={colors.primary}
          style={{ alignSelf: 'center' }}
          onPress={() => setIsVisible(!isVisible)}
        />

        <SendEmailDialog
          callForSubmissionId={item.callForSubmissionId}
          user={item}
          title={t('settings:advisors:label:resendInvite')}
          isOpen={isVisible}
          onClose={() => setIsVisible(false)}
        />
      </View>
    )
  }
}

const submissionsEditOrViewByAdminColumn = (canEditAll = false, table) => ({
  id: 'submissionsEditOrViewByAdminColumn',
  headerStyle,
  width: canEditAll ? 15 : 5,
  isEditable: false,
  role: null,
  Cell: ({ item }: CellRendererProps<any>) => {
    const { colors } = useTheme()
    const linkToScreen = useLinkToScreen()

    const onPress = () => {
      linkToScreen('SavedSubmission', { id: item?.id })
    }

    return (
      <View>
        <TouchableIcon
          testID={`${table}-viewIcon-${item.submissionInfo.name}`}
          width={20}
          height={20}
          disabled={false}
          name={canEditAll ? 'editUser' : 'view'}
          color={colors.primary}
          style={{ alignSelf: 'center' }}
          onPress={onPress}
        />
      </View>
    )
  }
})

export const getAdvisorsTableConfig = () => {
  const advisorsColumns = [
    advisorCFSSettingsNameColumn,
    rolesColumn,
    advisorEmailColumn,
    cfsSubmissionsCountColumn,
    submissionsCountColumn,
    actionsColumn
  ]

  const table: TableConfig<any> = {
    name: 'advisors-table',
    headerContainerStyle,
    LargeRow: DynamicAlternatingHoverableRow,
    SmallRow: SmallRow,
    columns: advisorsColumns.map(column => ({
      ...column
    })),
    enableFilters: false,
    showCsvButton: false,
    showPDFButton: false
  } as any

  return table
}

interface getPresentationsSubmissionTableConfig extends getTableConfigArgs {
  cohortId: string
  cohortLabel: string
  handleUpdateFlagSelection
}

export const getPresentationsSubmissionTableConfig = (
  args: getPresentationsSubmissionTableConfig
) => {
  const {
    cfsId,
    cohortId,
    role,
    engine,
    isInnovationNewDynamicTable,
    handleUpdateFlagSelection,
    categoryFilteredBy,
    statusFilteredBy
  } = args

  const presentationColumns = [
    isInnovationNewDynamicTable ? newSelectItemColumn : selectItemColumn,
    companyColumn,
    getCategoryColumn({}),
    interestColumn,
    memberFavoritesColumn,
    getPresentationFlagSelectionColumn({
      flag: 'isInvite',
      groupId: cohortId,
      handleUpdate: handleUpdateFlagSelection,
      header: 'curation:submissions:table:invite'
    }),
    getPresentationFlagSelectionColumn({
      flag: 'isSpotlight',
      groupId: cohortId,
      handleUpdate: handleUpdateFlagSelection,
      header: 'curation:submissions:table:spotlight'
    })
  ]

  const getCustomCsvColumns = (data: any[]): [any[], string] => {
    let companyVotesValidation = {}
    let companyVotes: any[] = []
    data.map(submission => {
      const submissionVotes = submission?.submissionVotes ?? []
      submissionVotes.map(sv => {
        if (!companyVotesValidation[sv?.company?.id]) {
          companyVotes.push({
            id: sv?.company?.id,
            name: sv?.company?.name
          })
          companyVotesValidation[sv?.company?.id] = true
        }
      })
    })
    return [
      companyVotes,
      [{ name: 'Innovator' }, ...companyVotes]
        .map(col => (col?.name ? `"${col?.name}"` : `"Unknown Member"`))
        .join(CSV_SEPARATOR_CHARACTER)
    ]
  }

  const getCustomCsvBody = (submissions: any[]) => {
    let submissionRows: any[] = []
    const [companyVotes, csvHeaders] = getCustomCsvColumns(submissions)

    submissions.map(submission => {
      const submissionVotes = submission?.submissionVotes ?? []
      const submissionRow = [`"${submission?.company?.name}"`]
      companyVotes.map(company => {
        if (submissionVotes.length > 0) {
          submissionVotes.map(sv => {
            if (company.id === sv?.company.id) {
              switch (sv?.vote) {
                case SubmissionVotingVoteEnum.NotInterested:
                  submissionRow.push(`"Not Interest"`)
                  break
                case SubmissionVotingVoteEnum.Shortlisted:
                  submissionRow.push(`"Favorite"`)
                  break
                default:
                  submissionRow.push(`"-"`)
                  break
              }
            } else {
              submissionRow.push(`"-"`)
            }
          })
        } else {
          submissionRow.push(`"-"`)
        }
      })
      submissionRows.push(submissionRow.join(CSV_SEPARATOR_CHARACTER))
    })
    return `${csvHeaders}\n${submissionRows.join('\n')}`
  }

  const table: TableConfig<any> = {
    name: TableNameEnum.Presentation,
    queryDynamicName: 'presentationSearchInnovationEngine',
    headerContainerStyle,
    LargeRow: DynamicAlternatingHoverableRow,
    SmallRow: SmallRow,
    // @ts-ignore
    columns: [...presentationColumns, dropDownIndicatorColumn].map(column => ({
      ...column,
      fixed: !![...commonFixedColumns].filter(
        fixedColumn => fixedColumn === column?.id
      ).length
    })),
    enableFilters: true,
    isSticky: true,
    engine: engine,
    hitKey: getEngineHitKey(engine),
    skipCache: true,
    customCsv: true,
    getCustomCsvBody,
    showCsvButton: true,
    showPDFButton: true,
    customQueryFields: InnovationSubmissionPresentationsFieldsFragment,
    showTotalElements: true,
    cohortId
  }

  table.queryConditions = {
    withSubmissionVotes: role !== RoleEnum.InnovationAdvisor
  }

  const baseFilters = getQueryFiltersByCFS(cfsId)
  const filters: AppSearchFiltersInput = {
    all: [
      { [appSearchColumnMap['hasBeenSubmitted']]: 'true' },
      { [appSearchColumnMap['submissionGroupIds']]: [cohortId] },
      categoryFilteredBy?.length
        ? { [appSearchColumnMap['category']]: categoryFilteredBy }
        : {},
      statusFilteredBy && statusFilteredBy === 'invite'
        ? { [appSearchColumnMap['invites']]: [cohortId] }
        : {},
      statusFilteredBy && statusFilteredBy === 'spotlight'
        ? { [appSearchColumnMap['spotlights']]: [cohortId] }
        : {}
    ],
    none: [
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.submission
      },
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.preCurate
      },
      {
        [appSearchColumnMap['submissionStep']]:
          CallForSubmissionStepEnum.advising
      }
    ]
  }

  table.filters = mergeFilters(baseFilters, filters)

  table.csvTypesMap = [
    {
      name: CSVTypeEnum.Presentation,
      customCsv: false
    },
    {
      name: CSVTypeEnum.PresentationInvitedInnovators,
      customCsv: false
    },
    {
      name: CSVTypeEnum.PresentationInnovatorScores,
      customCsv: false
    }
  ]

  table.getDetailedViewComponents = props => {
    const { item, leftTable, t } = props

    const detailItem = (title: string, value: string) => (
      <DetailItemView leftTable={leftTable}>
        <Text styles={{ fontWeight: 'bold' }}>{`${title}: `}</Text>
        <Text>{value}</Text>
      </DetailItemView>
    )

    let innovatorScore = `-`
    let newnessScore = '-'
    let interestScore = `-`
    let wassScore = getWASS(item)
    const submissionVotes: any[] = item?.submissionVotes ?? []
    const totalVotes = submissionVotes.length
    const voteMap = {
      [SubmissionVotingVoteEnum.NotInterested]: -1,
      [SubmissionVotingVoteEnum.Shortlisted]: 1
    }
    if (submissionVotes.length > 0) {
      innovatorScore = `${submissionVotes.reduce(
        (acc, sv) => acc + voteMap[sv.vote] + (sv.favorite ? 1 : 0),
        0
      )}`
      if (innovatorScore === '0') {
        innovatorScore = `-`
      }
    }

    if (totalVotes > 0) {
      const filteredSeenInnovator = submissionVotes.filter(sv => {
        const reason = JSON.parse(sv.reason ?? '{}')
        return reason?.seenInnovator === 'no'
      })
      newnessScore = `${(
        (filteredSeenInnovator.length / totalVotes) *
        100
      ).toFixed(0)}`
      newnessScore = newnessScore !== '0' ? `${newnessScore}  %` : '-'
    }

    if (totalVotes > 0) {
      const interestVotes = submissionVotes.reduce(
        (_, submissionVote) =>
          submissionVote.vote === SubmissionVotingVoteEnum.Shortlisted ? 1 : 0,
        0
      )
      if (interestVotes) {
        interestScore = `${((interestVotes / totalVotes) * 100).toFixed(0)} %`
      }
    }

    return leftTable
      ? [
          detailItem(
            t('curation:submissions:table:innovatorScore'),
            innovatorScore
          ),
          detailItem(
            t('curation:submissions:table:newnessScore'),
            newnessScore
          ),
          detailItem(
            t('curation:submissions:table:interestScore'),
            interestScore
          ),
          detailItem(t('curation:submissions:table:wass'), wassScore)
        ]
      : []
  }

  return table
}
