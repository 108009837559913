import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import styled, { useTheme } from 'styled-components/native'
import SkeletonContainer from '../../../../components/skeletonLoadings/SkeletonContainer'
import { CallForSubmissionFormSkeleton } from '../../SettingsScreen/components/Skeleton'
import { View } from 'react-native'
import DateService from '../../../../services/dateService'
import {
  QuestionBlock,
  StepDateRangeContainer
} from '../../SettingsScreen/components/SettingsStyledComponents'
import TextInput from '../../../../ui-library/TextInput'
import useTranslation from '../../../../hooks/useTranslation'
import { useFormik } from 'formik'
import {
  CallForSubmissionStepEnum,
  DateTime,
  OriginSourceEnum
} from '../../../../types'
import useUpsertCallForSubmissionMutation from '../../../Curation/hooks/useUpsertCallForSubmissionMutation'
import useToast from '../../../../hooks/useToast'
import Dropdown from '../../../../components/common/Dropdown'
import { StyledLabel } from '../../../../ui-library/TextInput/Label'
import useDelegationsQuery from '../../../Product/hooks/useDelegationsQuery'
import { Flex } from '../../../../components/FlexBox'
import DateInput from '../../../../ui-library/DateInput/DateInput'
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen'
import Button from '../../../../ui-library/Button'
import { useNavigation } from '@react-navigation/native'
import useLinkToScreen from '../../../../hooks/useLinkToScreen'
import useFormsQuery from '../../../Product/hooks/useFormsQuery'
import CheckBox from '../../../../ui-library/CheckBox'
import { ErrorText } from '../../../authentication/components/SharedStyledComponents'

const DisclaimerContainer = styled.View`
  flex-direction: column;
`
const DisclaimerCheckboxContainer = styled.TouchableOpacity`
  flex-direction: row;
  gap: ${({ theme }) => theme.space[2]}px;
`
const DisclaimerFieldsContainer = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[2]}px;
`
const DisclaimerField = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]}px;
`
const DisclaimerStyledLabel = styled(StyledLabel)`
  margin-bottom: 0px;
`

export const DisclaimerForm = ({ formik }) => {
  const { values, errors, handleChange, setFieldValue } = formik

  const { t } = useTranslation()

  const disErrors = [
    ...(errors?.disclaimerEventLocation
      ? [errors?.disclaimerEventLocation]
      : []),
    ...(errors?.disclaimerInnovatorFee ? [errors?.disclaimerInnovatorFee] : []),
    ...(errors?.disclaimerContactEmail ? [errors?.disclaimerContactEmail] : [])
  ]

  return (
    <DisclaimerContainer>
      <DisclaimerFieldsContainer>
        <DisclaimerField>
          <DisclaimerStyledLabel>
            {t('callsForSubmission:form:disclaimerEventLocation')}
          </DisclaimerStyledLabel>
          <TextInput
            small
            name="disclaimerEventLocation"
            value={values.disclaimerEventLocation}
            onChange={handleChange}
            containerStyles={{ marginTop: '18px' }}
          />
        </DisclaimerField>
        <DisclaimerField>
          <DisclaimerStyledLabel>
            {t('callsForSubmission:form:disclaimerInnovatorFee')}
          </DisclaimerStyledLabel>
          <TextInput
            small
            name="disclaimerInnovatorFee"
            value={values.disclaimerInnovatorFee}
            onChange={(e: any) => {
              const someValue = e?.currentTarget?.value || ''
              setFieldValue(
                'disclaimerInnovatorFee',
                someValue.replace(/\$[^\d,]+/g, '')
              )
            }}
            containerStyles={{ marginTop: '18px' }}
          />
        </DisclaimerField>
        <DisclaimerField>
          <DisclaimerStyledLabel>
            {t('callsForSubmission:form:disclaimerContactEmail')}
          </DisclaimerStyledLabel>
          <TextInput
            small
            name="disclaimerContactEmail"
            value={values.disclaimerContactEmail}
            onChange={handleChange}
            containerStyles={{ marginTop: '18px' }}
          />
        </DisclaimerField>
      </DisclaimerFieldsContainer>
      <DisclaimerFieldsContainer>
        {disErrors && <ErrorText>{disErrors.join(' - ')}</ErrorText>}
      </DisclaimerFieldsContainer>
    </DisclaimerContainer>
  )
}

interface StepDateRangeFormField {
  id?: string
  curationStep: CallForSubmissionStepEnum
  openAt: DateTime
  closeAt: DateTime
}

interface CFSFormValues {
  id?: string
  name: string
  delegation: string
  formId: string
  stepDateRanges: StepDateRangeFormField[]
  // disclaimer fields
  disclaimer?: boolean
  disclaimerEventLocation?: string
  disclaimerInnovatorFee?: string
  disclaimerContactEmail?: string
}

const getCFSFormValidationSchema = t => {
  const StepDateRangeSchema = Yup.object().shape({
    id: Yup.string(),
    curationStep: Yup.string().required('Curation Step required'),
    openAt: Yup.string().required(t('validation:error:required')),
    closeAt: Yup.string().required(t('validation:error:required'))
  })

  return Yup.object().shape({
    id: Yup.string().notRequired(),
    name: Yup.string().trim().required(),
    delegation: Yup.string().required(),
    formId: Yup.string().required(),
    stepDateRanges: Yup.array()
      .min(1, 'Curation Step required')
      .of(StepDateRangeSchema)
      .required('Curation Step required'),
    disclaimer: Yup.boolean().notRequired(),
    disclaimerEventLocation: Yup.string()
      .trim()
      .when('disclaimer', {
        is: true,
        then: Yup.string().trim().required('Event location required')
      }),
    disclaimerInnovatorFee: Yup.string()
      .trim()
      .when('disclaimer', {
        is: true,
        then: Yup.string().trim().required('Innovator fee required')
      }),
    disclaimerContactEmail: Yup.string()
      .trim()
      .when('disclaimer', {
        is: true,
        then: Yup.string()
          .email('Must enter a valid email address')
          .required('Contact email required')
      })
  })
}

const CallForSubmissionForm = () => {
  const { t } = useTranslation()
  const isSmallScreen = useIsSmallScreen()
  const { colors, space } = useTheme()
  const navigation = useNavigation<any>()
  const { delegations, loading: loadingDelegations } = useDelegationsQuery()
  const { forms, loading: loadingForms } = useFormsQuery()
  const { setToastMessage } = useToast()
  const linkToScreen = useLinkToScreen()
  const [formId, setFormId] = useState<string>('')
  const { getDate, getDateByAddingTime, getFormatWithTimeZone } = DateService
  const defaultValues: CFSFormValues = {
    id: '',
    name: '',
    delegation: '',
    formId: '',
    stepDateRanges: [],
    disclaimer: false,
    disclaimerEventLocation: '',
    disclaimerInnovatorFee: '',
    disclaimerContactEmail: ''
  }
  const {
    upsertCallForSubmission,
    loading: isProcessing
  } = useUpsertCallForSubmissionMutation(undefined, false)

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    submitForm,
    dirty,
    resetForm
  } = useFormik<CFSFormValues>({
    validationSchema: getCFSFormValidationSchema(t),
    // @ts-ignore
    initialValues: defaultValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({
      id,
      name,
      delegation,
      formId,
      stepDateRanges,
      disclaimer,
      disclaimerEventLocation,
      disclaimerInnovatorFee,
      disclaimerContactEmail
    }: CFSFormValues) => {
      const [submissionStepDateRange] = stepDateRanges
      const { data, errors } = await upsertCallForSubmission({
        variables: {
          id: id ?? null,
          name: name?.trim?.(),
          delegationIds: [delegation],
          formId,
          originSource: OriginSourceEnum.Innovation,
          stepDateRanges: [submissionStepDateRange],
          academyElegible: false,
          disclaimer: disclaimer ?? false,
          disclaimerEventLocation: disclaimerEventLocation?.length
            ? disclaimerEventLocation?.trim?.()
            : undefined,
          disclaimerInnovatorFee: disclaimerInnovatorFee?.length
            ? disclaimerInnovatorFee?.trim?.()
            : undefined,
          disclaimerContactEmail: disclaimerContactEmail?.length
            ? disclaimerContactEmail?.trim?.()
            : undefined
        }
      })
      if (!errors) {
        setToastMessage(t(`callsForSubmission:success:changesSaved`))
        linkToScreen('CFSSettings', {
          cfsId: data?.upsertCallForSubmission?.id
        })
      }
    }
  })

  useEffect(() => {
    if (formId) {
      setFieldValue('formId', formId)
    }
  }, [formId])

  const stepName = t(
    `callsForSubmission:step:name:${CallForSubmissionStepEnum.submission}`
  )
  const convertDateToEST = date => {
    return date ? new Date(getFormatWithTimeZone(new Date(date))) : undefined
  }

  const today = new Date()

  const getInitialDateValidation = (startAt: Date) => {
    return startAt > today ? today : startAt
  }

  const getErrorMessage = (index, field) => {
    // @ts-ignore
    return errors?.stepDateRanges?.length > 0 && errors?.stepDateRanges[index]
      ? errors?.stepDateRanges[index][field]
      : null
  }

  const isEnabled = !isProcessing && !loadingDelegations && !loadingForms

  return (
    <>
      <SkeletonContainer
        isLoading={loadingDelegations}
        Skeleton={CallForSubmissionFormSkeleton}
      >
        <QuestionBlock style={{ maxWidth: '950px' }}>
          <TextInput
            name="name"
            value={values.name}
            errorMessage={errors.name}
            onChange={handleChange}
            label={t('callsForSubmission:form:name')}
          />
        </QuestionBlock>
        <QuestionBlock style={{ maxWidth: '950px' }}>
          <View>
            <StyledLabel>{t('callsForSubmission:form:delegation')}</StyledLabel>
            <Dropdown
              name="delegations"
              options={
                delegations &&
                delegations.map(del => ({
                  label: `${del.description} (${del.name})`,
                  value: del.id
                }))
              }
              placeholder={''}
              onSelect={value => {
                setFieldValue('delegation', value)
                const delegation = delegations.find(del => del.id === value)
                const delegationForm =
                  delegation && delegation?.forms ? delegation.forms[0] : null
                if (delegationForm) {
                  setFormId(delegationForm.id)
                }
              }}
              value={values.delegation}
              hasError={!!errors.delegation && !!touched.delegation}
              style={{ minWidth: '100%', marginBottom: '7px' }}
              isClearable
              menuPortalTarget={document?.body}
            />
          </View>
        </QuestionBlock>
        <QuestionBlock style={{ maxWidth: '950px' }}>
          <DisclaimerContainer>
            <DisclaimerCheckboxContainer
              onPress={() => setFieldValue('disclaimer', !values.disclaimer)}
            >
              <CheckBox
                checked={values.disclaimer || false}
                onPress={() => setFieldValue('disclaimer', !values.disclaimer)}
              />
              <StyledLabel>
                {t('callsForSubmission:form:disclaimerCheckbox')}
              </StyledLabel>
            </DisclaimerCheckboxContainer>
            {values?.disclaimer ? (
              <DisclaimerForm
                formik={{ values, errors, handleChange, setFieldValue } as any}
              />
            ) : null}
          </DisclaimerContainer>
        </QuestionBlock>
        <QuestionBlock style={{ maxWidth: '950px' }}>
          <View>
            <StyledLabel>{t('callsForSubmission:form:form')}</StyledLabel>
            <Dropdown
              name="formId"
              options={
                forms &&
                forms.map(form => ({
                  label: form.name,
                  value: form.id
                }))
              }
              placeholder={''}
              onSelect={value => setFieldValue('formId', value)}
              value={values.formId}
              hasError={!!errors.formId && !!touched.formId}
              style={{ minWidth: '100%', marginBottom: '7px' }}
              isClearable
              menuPortalTarget={document?.body}
            />
          </View>
        </QuestionBlock>

        <QuestionBlock style={{ maxWidth: '950px' }}>
          <View>
            <StyledLabel>
              {t('callsForSubmission:form:submissionStep')}
            </StyledLabel>
            <Flex flexDirection="row" width="100%">
              <StepDateRangeContainer isAlternativeView stepName={stepName}>
                <DateInput
                  isReadOnly={!isEnabled}
                  isDateIconVisible={isEnabled}
                  isClosableIconVisible={isEnabled}
                  value={convertDateToEST(values.stepDateRanges[0]?.openAt)}
                  label={t('curation:nextSteps:openAt')}
                  handleChange={value => {
                    setFieldValue(`stepDateRanges[0].openAt`, value)
                    setFieldValue(
                      `stepDateRanges[0].curationStep`,
                      CallForSubmissionStepEnum.submission
                    )
                  }}
                  minimumDate={getInitialDateValidation(
                    getDate(values.stepDateRanges[0]?.openAt)
                  )}
                  maximumDate={
                    values.stepDateRanges[0]?.openAt
                      ? getDateByAddingTime(
                          values.stepDateRanges[0]?.closeAt,
                          -1,
                          'day'
                        )
                      : undefined
                  }
                  containerStyles={{ backgroundColor: 'white' }}
                  parentContainerStyles={{
                    width: isSmallScreen ? '100%' : '47%'
                  }}
                  dateIconColor={colors.blueBackground}
                  errorMessage={getErrorMessage(0, 'openAt')}
                  withTime
                />
                <DateInput
                  dateIconColor={colors.blueBackground}
                  containerStyles={{ backgroundColor: 'white' }}
                  parentContainerStyles={{
                    width: isSmallScreen ? '100%' : '47%'
                  }}
                  isReadOnly={!isEnabled || !values.stepDateRanges[0]?.openAt}
                  isDateIconVisible={isEnabled}
                  isClosableIconVisible={isEnabled}
                  value={convertDateToEST(values.stepDateRanges[0]?.closeAt)}
                  label={t('curation:nextSteps:closeAt')}
                  handleChange={value => {
                    setFieldValue(`stepDateRanges[0].closeAt`, value)
                  }}
                  minimumDate={getDateByAddingTime(
                    values.stepDateRanges[0]?.openAt,
                    1,
                    'day'
                  )}
                  errorMessage={getErrorMessage(0, 'closeAt')}
                  withTime
                />
                {errors?.stepDateRanges ? (
                  <ErrorText>{errors?.stepDateRanges}</ErrorText>
                ) : null}
              </StepDateRangeContainer>
            </Flex>
          </View>
        </QuestionBlock>
      </SkeletonContainer>

      <View
        style={{
          flexDirection: 'row',
          bottom: space[5],
          position: 'absolute'
        }}
      >
        <Button
          type="outline"
          onPress={() => {
            resetForm()
            navigation.navigate('CurateCFSList')
          }}
          title={t('curation:buttons:cancel')}
          containerStyle={{ marginRight: space[3] }}
        />
        <Button
          type="solid"
          onPress={async () => {
            await submitForm()
          }}
          title={t('curation:buttons:save')}
          disabled={!dirty}
          loading={isProcessing}
        />
      </View>
    </>
  )
}

export default CallForSubmissionForm
