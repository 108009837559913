import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import Firebase from './Firebase'
import { getAuth as getFBAuth } from 'firebase/auth'
import getApiUrl from './utils/getApiUrl'

const setAuthContext = async (previousContext: any) => {
  const { currentUser } = getFBAuth(Firebase)
  const appId = { 'App-Id': 'innovation' }

  if (!currentUser) {
    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        ...appId
      }
    }
  }

  const authToken = await currentUser.getIdToken()

  return {
    headers: {
      ...previousContext.headers,
      Authorization: `Bearer ${authToken}`,
      ...appId
    }
  }
}

const authLink = setContext((_, previousContext) =>
  setAuthContext(previousContext)
)

const httpLink = createHttpLink({
  uri: getApiUrl('http')
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${getApiUrl('ws')}/graphql`,
    connectionParams: async () => {
      const { currentUser } = getFBAuth(Firebase)
      if (!currentUser) {
        return {}
      }

      const authToken = await currentUser.getIdToken()
      return {
        authToken
      }
    },
    webSocketImpl: WebSocket
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink)
)

const mergeResultsPolicy = {
  fields: {
    hits: {
      merge(existing = [], incoming: any[], { variables }: any) {
        const { offset } = variables
        return offset ? [...existing, ...incoming] : incoming
      }
    }
  }
}

const client = new ApolloClient({
  connectToDevTools: true,
  cache: new InMemoryCache({
    typePolicies: {
      SearchResult: mergeResultsPolicy
    }
  }),
  link: splitLink,
  name: 'Innovation'
})

export default client
