import React, { FC, useMemo } from 'react'

import withNavigationPermissions from '../../navigation/withNavigationPermissions'

import ScreenContainer from '../../components/layout/ScreenContainer'
import { DynamicTable, useTable } from '../../components/Table'
import { DynamicTable as NewDynamicTable } from '../../components/DynamicTable'

import { Container } from '../CallForSubmission/components/SharedComponents'
import { TableContainer } from '../../components/StaticForm'

import { getAdvisorsCurationTableConfig } from './constants'

import {
  RoleEnum,
  CallForSubmissionStepEnum,
  Person,
  AdvisorReview,
  Answer,
  InnovationAccessKey,
  CallForSubmission
} from '../../types'

import useTranslation from '../../hooks/useTranslation'
import { Question } from '../../types/form'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import { NEW_DYNAMIC_TABLE_FF } from '../../components/DynamicTable/constants'

export interface SubmissionsProps {
  id: string
  role: RoleEnum
  currentCFSStep: CallForSubmissionStepEnum | null
  delegations: any[]
  reviewFormQuestions?: Question[]
  callForSubmission?: CallForSubmission
}

const getEmailAddress = (person: Person, t: any) => {
  if (person?.emailAddresses && person?.emailAddresses.length > 0) {
    return person?.emailAddresses[0].email
  }
  return t('curation:advisors:table:noEmail')
}

const advisorCheckQuestionAnswerCountInCFS = (
  cfs,
  advisorId,
  question
): number => {
  const questionId = cfs?.reviewFormQuestions?.find(rfq =>
    rfq.questionText.toLowerCase().includes(question)
  )?.id
  if (!questionId) {
    return 0
  }
  return (
    cfs?.innovationSubmissions
      .reduce((accum: AdvisorReview[], innovationSubmission) => {
        const advisorReviews =
          innovationSubmission?.advisorsReviews?.filter(
            advRev => advRev.advisorId === advisorId
          ) || []

        return [...accum, ...advisorReviews]
      }, [])
      .reduce((accum: Answer[], advisorReview) => {
        return [...accum, ...advisorReview.answers]
      }, [])
      .filter(answer => answer.questionId === questionId && !!answer.value)
      .length || 0
  )
}

const advisorCheckStatusCountInCFS = (cfs, advisorId): AdvisorReview[] => {
  return cfs?.innovationSubmissions.reduce(
    (accum: AdvisorReview[], innovationSubmission) => {
      const advisorReviews =
        innovationSubmission?.advisorsReviews?.filter(
          advRev => advRev.advisorId === advisorId && advRev.answers.length > 0
        ) || []

      return [...accum, ...advisorReviews]
    },
    []
  )
}

const AdvisingScreen: FC<SubmissionsProps> = ({ callForSubmission }) => {
  const isInnovationNewDynamicTable = useFeatureFlag(NEW_DYNAMIC_TABLE_FF)
  const { t } = useTranslation()
  const advisorsInfo = callForSubmission?.advisorsInfo ?? []

  const currentAdvisors = useMemo(
    () =>
      !isInnovationNewDynamicTable
        ? callForSubmission?.advisorsUsers?.map(adv => ({
            advisorReviews: advisorCheckStatusCountInCFS(
              callForSubmission,
              adv.id
            ),
            callForSubmissionId: callForSubmission.id,
            id: `${adv?.id}`,
            value: `${adv?.id}`,
            totalCfsSubmissions: callForSubmission.innovationSubmissions?.filter(
              submission => submission.hasBeenSubmitted
            ).length,
            cfsSubmissionReviews: adv?.cfsSubmissionReviews,
            submissionsFavoritesCount: advisorCheckQuestionAnswerCountInCFS(
              callForSubmission,
              adv.id,
              'favorite'
            ),
            academyAnswersCount: advisorCheckQuestionAnswerCountInCFS(
              callForSubmission,
              adv.id,
              'academy'
            ),
            submissionsCount: adv?.submissionsCount,
            label: `${adv?.person.firstName} ${
              adv?.person.lastName
            } (${getEmailAddress(adv?.person, t)})`,
            fullName: `${adv?.person?.firstName} ${adv?.person?.lastName}`
          }))
        : advisorsInfo.map(ai => ({
            callForSubmissionId: callForSubmission?.id,
            id: `${ai.user.id}`,
            value: `${ai.user.id}`,
            fullName: `${ai.user.person.firstName} ${ai.user.person.lastName}`,
            cfsSubmissionReviews: ai.reviews,
            academyAnswersCount: ai.academy,
            submissionsFavoritesCount: ai.favorite,
            advisorReviews: ai.status
          })),
    [
      callForSubmission?.advisorsUsers,
      advisorsInfo,
      isInnovationNewDynamicTable
    ]
  )

  const tableProps = useTable({
    config: getAdvisorsCurationTableConfig(isInnovationNewDynamicTable)
  })

  const TableComponent = isInnovationNewDynamicTable
    ? NewDynamicTable
    : DynamicTable
  return (
    <ScreenContainer header="menu-header" style={{ padding: 0 }}>
      <Container>
        <TableContainer height="100%">
          <TableComponent
            {...tableProps}
            emptyMessage={t('curation:submissions:noSubmissions')}
            data={currentAdvisors}
          />
        </TableContainer>
      </Container>
    </ScreenContainer>
  )
}

const Advising = withNavigationPermissions(AdvisingScreen, [
  InnovationAccessKey.CURATE
])

export default Advising
