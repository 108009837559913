import React, { useCallback, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { pick } from 'ramda'
import { LoginCheckSubStateProps } from '../../../AppCoordinator/LoginCheck'

import useIsSmallScreen from '../../../hooks/useIsSmallScreen'
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { useLazyQuery } from '../../../hooks/useQuery'
import useSchemaWithTranslation from '../../../hooks/useSchemaWithTranslation'

import useRegistrationMutation from '../mutations/useRegistrationMutation'
import Firebase from '../../../Firebase'
import {
  getAuth as getFBAuth,
  updatePassword as updateFBPassword
} from 'firebase/auth'
import useRegistrationDataQuery from '../queries/useRegistrationDataQuery'
import useUpdateInnovationOnboarded from '../mutations/useUpdateInnovationOnboarded'
import PersonInfoForm from '../components/PersonInfoForm'
import PasswordMatchForm from '../components/PasswordMatchForm'
import CompanyInfoForm from '../components/CompanyInfoForm'
import {
  INPUT_MAX_LENGTH_MD,
  INPUT_MAX_LENGTH_SM,
  DOMAIN_REGEX
} from '../constants'
import Divider from '../components/sharedComponents/Divider'
import { H3 } from '../../../components/common/Text'
import CallToActionBtns from '../components/sharedComponents/CallToActionBtns'
import { Column, Layout } from './Layout'
import client from '../../../API'
import useMixpanel, { EVENT_MAP } from '../../../hooks/useMixpanel'
import { Company } from '../../../types'
import { ActivityIndicator, Platform } from 'react-native'
import useDeleteNonOnboardedInnovator from '../mutations/useDeleteNonOnboardedInnovator'
import { removeNonNumeric } from '../../../utils/changeFormikText'
import currentUserQuery from '../../../AppRoot/controllers/currentUserQuery'

// JC: Max 32-bit signed integer
// This is max value supported by GraphQL and Postgres Integer value
const MAX_FUNDING = 2147483647

const initialValues = {
  firstName: '',
  lastName: '',
  jobTitle: '',
  email: '',
  password: '',
  confirmPassword: '',
  companyName: '',
  companyURL: '',
  companyEmailDomainInput: '',
  companyEmailDomains: [],
  country: '',
  employeeCount: '',
  delegationInterest: [],
  yearFounded: '',
  funding: ''
}

Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    return list?.length === new Set(list?.map(mapper)).size
  })
})

const getRegistrationSchema = t => {
  return Yup.object().shape({
    firstName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
    lastName: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
    jobTitle: Yup.string().max(INPUT_MAX_LENGTH_SM).required('Required'),
    email: Yup.string().required('Required'),
    password: Yup.string()
      .min(6, t('error:auth:tooShort'))
      .required(t('error:auth:required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null as any], t('error:auth:match'))
      .required(t('error:auth:againRequired')),
    companyName: Yup.string().max(INPUT_MAX_LENGTH_MD).required('Required'),
    companyURL: Yup.string().max(INPUT_MAX_LENGTH_MD).required('Required'),
    companyEmailDomainInput: Yup.string()
      .max(INPUT_MAX_LENGTH_MD)
      .matches(DOMAIN_REGEX),

    country: Yup.string().required('Required'),
    employeeCount: Yup.string().required('Required'),
    delegationInterest: Yup.array().required('Required').of(Yup.string()),
    yearFounded: Yup.number()
      .test(
        'len',
        t('error:auth:invalidYear'),
        val => val?.toString()?.length === 4
      )
      .max(new Date().getFullYear(), t('error:auth:invalidYear'))
      .required('Required'),
    funding: Yup.string()
      .max(MAX_FUNDING, t('error:auth:invalidFunding'))
      .required('Required')
  })
}

const RegistrationScreen = ({ send }: LoginCheckSubStateProps) => {
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  const { data, loading } = useRegistrationDataQuery()
  const isSmallScreen = useIsSmallScreen()
  const registrationSchema = useSchemaWithTranslation(getRegistrationSchema)
  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()
  const {
    currentPersonId,
    setCurrentUser,
    setCompany,
    currentUserId
  } = useCurrentUser()
  const { trackWithProperties } = useMixpanel()
  const [updateCurentUser] = useLazyQuery(currentUserQuery, {
    fetchPolicy: 'no-cache',
    onError: () => {
      setToastErrorMessage(t('error:general:currentUser'))
    },
    onCompleted: (data: any) => {
      if (data?.currentUser) {
        setCurrentUser(data?.currentUser)
      }
      send('REGISTERED')
    }
  })

  const {
    cancelRegistration,
    loading: cancelLoading
  } = useDeleteNonOnboardedInnovator()

  const logout = async () => {
    await cancelRegistration(currentUserId)
    await getFBAuth(Firebase).signOut()
    setCurrentUser(null)
    await client.clearStore()
    if (Platform.OS === 'web') {
      document.location.reload()
    }
  }

  const commonMutationProps = {
    onError: e => {
      if (e.message?.includes('cannot be added because it has active users')) {
        setToastErrorMessage(t('error:auth:domainInUsed'))
      } else {
        setToastErrorMessage(t('error:auth:failedRegister'))
      }
      setFetchLoading(false)
    }
  }

  const updateOnboarding = useUpdateInnovationOnboarded({
    variables: {
      personId: currentPersonId
    },
    onCompleted: () => {
      updateCurentUser()
    },
    ...commonMutationProps
  })

  const [register, registerLoading] = useRegistrationMutation({
    onCompleted: () => {
      trackWithProperties(EVENT_MAP.auth.registered, {
        personId: currentPersonId
      })
      updateOnboarding()
    },
    ...commonMutationProps
  })

  const updatePassword = async password => {
    const currentUserFB = getFBAuth(Firebase).currentUser
    if (currentUserFB) {
      try {
        await updateFBPassword(currentUserFB, password)
      } catch (error) {
        setToastErrorMessage(t('error:auth:changePassword'))
        setFetchLoading(false)
      }
    }
  }

  const submitRegistration = useCallback(
    async (values: any) => {
      setFetchLoading(true)
      const variables = {
        ...pick(
          ['firstName', 'lastName', 'jobTitle', 'email', 'companyName'],
          values
        ),
        personId: currentPersonId,
        originId: data?.origins?.find(o => o.source === 'innovation')?.id,
        profile: {
          ...pick(['employeeCount'], values),
          countryId: values.country,
          url: values.companyURL,
          domains: values.companyEmailDomains,
          delegationInterest: values.delegationInterest,
          yearFounded: new Date(values.yearFounded, 0, 1).toISOString(),
          funding: removeNonNumeric(values.funding)
        },
        termsId: data?.terms?.id,
        privacyId: data?.privacy?.id,
        cookieId: data?.cookie?.id,
        userId: currentUserId
      }
      await updatePassword(values.password)
      const { data: registerData } = await register({ variables })
      setCompany(registerData?.company as Company)
    },
    [data?.origins, currentPersonId]
  )

  if (loading) {
    return (
      <Layout height={1100}>
        <ActivityIndicator size="large" />
      </Layout>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitRegistration}
      validationSchema={registrationSchema}
    >
      {(formikProps: FormikProps<typeof initialValues>) => {
        const { isValidating, isSubmitting, dirty, isValid } = formikProps
        const disableButton: boolean =
          isValidating || isSubmitting || !dirty || !isValid || fetchLoading
        return (
          <Layout height={1220}>
            <Column
              padding={isSmallScreen ? '40px 24px 0 15px' : '0 16px'}
              alignSelf={'flex-start'}
            >
              <H3
                styles={{
                  textAlign: 'center',
                  fontWeight: '300',
                  marginBottom: isSmallScreen ? 10 : 30
                }}
                h3Style={{ fontSize: isSmallScreen ? '29px' : '36px' }}
              >
                {t('auth:register:header')}
              </H3>
              <PersonInfoForm {...formikProps} />
              <PasswordMatchForm {...formikProps} />
            </Column>

            <Divider />

            <Column
              padding={
                isSmallScreen ? '0 24px 24px 15px' : '73px 16px 450px 16px'
              }
              alignSelf={'flex-start'}
            >
              <CompanyInfoForm {...formikProps} />
              <CallToActionBtns
                primaryBtn={{
                  title: t('auth:buttons:submit'),
                  isProcessing: registerLoading,
                  disabled: disableButton,
                  onPress: () => {
                    formikProps
                      .validateForm()
                      .then(() => formikProps.submitForm())
                  }
                }}
                secondaryBtn={{
                  title: t('common:buttons:cancel'),
                  isProcessing: cancelLoading,
                  disabled: cancelLoading,
                  onPress: logout
                }}
              />
            </Column>
          </Layout>
        )
      }}
    </Formik>
  )
}

export default RegistrationScreen
