import theme from '../../../../constants/Theme'
import {
  CallForSubmissionStatusEnum,
  CallForSubmissionStepEnum
} from '../../../../types'
import { filterType } from '../../types'

export const searchDebouncedInputStyles = {
  marginTop: theme.sizes[2],
  borderRadius: theme.sizes[1],
  paddingLeft: theme.sizes[2],
  fontSize: theme.fontSizes[3],
  backgroundColor: theme.colors.background
}

export const textInputStyle = {
  borderRadius: 4,
  marginTop: 4,
  marginBottom: 4,
  paddingLeft: 8,
  width: '100%',
  fontSize: theme.fontSizes[3]
}

export const statusFilter = (t, includeUpcoming = true) => {
  const options = [
    {
      label: t('callsForSubmission:status:open'),
      value: CallForSubmissionStatusEnum.open
    },
    includeUpcoming
      ? {
          label: t('callsForSubmission:status:upcoming'),
          value: CallForSubmissionStatusEnum.upcoming
        }
      : null,
    {
      label: t('callsForSubmission:status:closed'),
      value: CallForSubmissionStatusEnum.closed
    }
  ].filter(Boolean)

  const filter = {
    key: 'statusFilterId',
    type: 'filter' as filterType,
    options
  }

  return filter
}

export enum AdvisorStatusFilterValueEnum {
  'pending',
  'advised'
}

export const advisorStatusFilterOptions = t => [
  {
    label: t('curation:advisors:status:advised'),
    value: AdvisorStatusFilterValueEnum.advised
  },
  {
    label: t('curation:advisors:status:pending'),
    value: AdvisorStatusFilterValueEnum.pending
  }
]

export const advisorStatusFilters = {
  pending: advisorId => ({
    none: [{ advisors_who_advised: advisorId }],
    advisors_who_advised: undefined
  }),
  advised: advisorId => ({
    advisors_who_advised: advisorId,
    none: []
  }),
  clear: {
    none: [],
    advisors_who_advised: undefined
  }
}

export const curationStepFilterOptions = [
  {
    label: 'Submission',
    value: CallForSubmissionStepEnum.submission
  },
  {
    label: 'Pre-curate',
    value: CallForSubmissionStepEnum.preCurate
  },
  {
    label: 'Advising',
    value: CallForSubmissionStepEnum.advising
  },
  {
    label: 'Cohort Assignments',
    value: CallForSubmissionStepEnum.cohort
  },
  {
    label: 'Voting',
    value: CallForSubmissionStepEnum.voting
  },
  {
    label: 'Final Selections',
    value: CallForSubmissionStepEnum.finalSelections
  },
  {
    label: 'Invites',
    value: CallForSubmissionStepEnum.selection
  },
  {
    label: 'Event',
    value: CallForSubmissionStepEnum.event
  }
]
