import React, { memo } from 'react'
import { View } from 'react-native'
import { Svg, Path, Circle } from 'react-native-svg'
import Icons from './icons'

// TODO: As of now, there's an issue in react-native-svg where all elements are focusable and the fix hasn't been merged:
// https://github.com/react-native-svg/react-native-svg/pull/1585
export interface IconProps {
  name: string
  color?: string
  width?: number
  height?: number
  style?: object | object[]
}

const SinglePath = ({ pathConfig, color }) => {
  const { d, stroke, fill, ...rest } = pathConfig

  const pathProps = {
    ...rest,
    ...(stroke
      ? {
          stroke: color
        }
      : fill
      ? { fill: color }
      : {})
  }
  return (
    <Path
      d={d}
      fillRule="evenodd"
      clipRule="evenodd"
      tabIndex={-1}
      {...pathProps}
    />
  )
}

const SingleCircle = ({ circleConfig, color }) => {
  const { cx, cy, r, stroke, fill, ...rest } = circleConfig

  const circleProps = {
    ...rest,
    ...(stroke
      ? {
          stroke: color
        }
      : fill
      ? { fill: color }
      : {})
  }
  return <Circle cx={cx} cy={cy} r={r} {...circleProps} />
}

const Icon = memo(
  ({
    name,
    color = '#fff',
    width = 28,
    height = 28,
    style,
    ...rest
  }: IconProps) => {
    const iconConfig = Icons[name]

    if (!iconConfig) {
      return null
    }

    const { viewBox, path, paths, fill, circle, transform } = iconConfig

    return (
      <View pointerEvents="none" style={style}>
        <Svg
          width={`${width}`}
          height={`${height}`}
          viewBox={viewBox}
          {...(transform ? { transform } : {})}
          {...(fill ? { fill } : {})}
          {...rest}
          // @ts-ignore
          tabIndex={-1}
        >
          {!paths ? (
            <Path
              d={path}
              fill={color}
              fillRule="evenodd"
              clipRule="evenodd"
              // @ts-ignore
              tabIndex={-1}
            />
          ) : (
            paths.map((currentPath, index) => (
              <SinglePath
                key={`icon-${name}-path-${index}`}
                pathConfig={currentPath}
                color={color}
              />
            ))
          )}
          {circle
            ? circle.map((currentCircle, index) => (
                <SingleCircle
                  key={`icon-${name}-circle-${index}`}
                  circleConfig={currentCircle}
                  color={color}
                />
              ))
            : null}
        </Svg>
      </View>
    )
  }
)

export default Icon
