import { useCallback, useMemo } from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { CompanyProfileValues, OriginSourceEnum } from '../../../types'
import currentUserQuery from '../../../AppRoot/controllers/currentUserQuery'
import useCurrentUser from '../../../hooks/useCurrentUser'
import { removeNonNumeric } from '../../../utils/changeFormikText'

const updateCompanyMutation = gql`
  mutation updateCompany(
    $id: String!
    $name: String!
    $originSource: OriginSourceEnum!
    $profile: CompanyProfileInput
  ) {
    updateCompany(
      id: $id
      name: $name
      originSource: $originSource
      profile: $profile
    ) {
      id
      name
      companyProfile {
        id
        url
        yearFounded
        funding
        employeeCount
        domains {
          id
          name
        }
        delegationInterest {
          id
          name
        }
        country {
          id
          name
        }
      }
    }
  }
`

interface UpdateCompanyParams {
  id: string
  refetchQueries: string[]
}

const useUpdateCompany = ({
  id,
  refetchQueries = []
}: UpdateCompanyParams): [
  (values: CompanyProfileValues) => void,
  boolean,
  any,
  any
] => {
  const { setCompany } = useCurrentUser()
  const [updateCompanyProfile, { loading, error, data }] = useMutation(
    updateCompanyMutation
  )

  const upsertSuccess = useMemo(() => data?.updateCompany, [data])

  const upsertError = useMemo(() => error?.message, [error])

  const updateCompany = useCallback(
    async ({ name, ...profile }: CompanyProfileValues) => {
      const { data } = await updateCompanyProfile({
        variables: {
          id,
          name,
          originSource: OriginSourceEnum.Innovation,
          profile: {
            ...profile,
            funding: removeNonNumeric(profile.funding),
            yearFounded: new Date(
              parseInt(profile.yearFounded),
              0,
              1
            ).toISOString()
          }
        },
        refetchQueries: [
          'currentUser',
          { query: currentUserQuery },
          ...refetchQueries
        ]
      })
      setCompany(data.updateCompany)
    },
    [id, updateCompanyProfile]
  )

  return [updateCompany, loading, upsertError, upsertSuccess]
}

export default useUpdateCompany
