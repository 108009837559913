import React, { useContext, useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { ThemeContext } from 'styled-components/native'
import LoginScreen from '../../screens/authentication/LoginScreen'
import AppNavigator from './AppNavigator'
import useCurrentUser from '../../hooks/useCurrentUser'
import LegalScreen from '../../screens/Legal/LegalScreen'
import InvitationAuthCodeScreen from '../../screens/Settings/AuthCodeScreen/'
import useMixpanel from '../../hooks/useMixpanel'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { RootNavigatorStackParamList } from '../../types/rootStackParamsList'

// To specify global type for the root navigator which will be used as the default type:
// https://reactnavigation.org/docs/typescript/#specifying-default-types-for-usenavigation-link-ref-etc
declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootNavigatorStackParamList {}
  }
}

const Stack = createStackNavigator()

const RootNavigator = () => {
  const { setUTMParams } = useMixpanel()
  const theme = useContext(ThemeContext) as any
  const { firebaseToken } = useCurrentUser()
  const { search } = window.location

  useEffect(() => {
    if (search) {
      setUTMParams(window.location.toString())
    }
  }, [search])

  const screenOptions = {
    headerShown: false,
    cardStyle: {
      backgroundColor: theme.colors.appBackground,
      flex: 1
    }
  }

  return (
    <Stack.Navigator screenOptions={screenOptions}>
      {firebaseToken ? (
        <Stack.Screen
          name="App"
          component={AppNavigator}
          options={{ headerShown: false }}
        />
      ) : (
        <>
          <Stack.Screen
            name="Login"
            component={LoginScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="JoinTeam"
            component={InvitationAuthCodeScreen}
            options={{ headerShown: false }}
          />
        </>
      )}
      <Stack.Screen
        name="Legal"
        component={LegalScreen}
        options={{ headerShown: false }}
      />
    </Stack.Navigator>
  )
}

export default RootNavigator
