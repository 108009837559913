import React from 'react'
import { useTheme } from 'styled-components/native'
import {
  LabelAndTitleCell,
  SubtitleCell
} from '../../components/DynamicTable/components/Table/Item/common/TitleCellContent'
import { ModalTabsEnum } from './FormsManagement/types'
import useTranslation from '../../hooks/useTranslation'
import { TouchableOpacity } from 'react-native'
import AnimatedComponent from '../../components/common/AnimatedComponent'
import { Text } from '../../components/common/Text'

export const Content = ({ isSmallScreen, label, title, style = {} }) => {
  return isSmallScreen ? (
    <LabelAndTitleCell label={label} title={title} style={style} />
  ) : (
    <SubtitleCell text={title} style={style} />
  )
}

export const ModalTab = ({
  label,
  tabKey,
  isActive,
  setActiveOption
}: {
  label: string
  tabKey: ModalTabsEnum
  isActive: boolean
  setActiveOption: (value: ModalTabsEnum) => void
}) => {
  const { colors } = useTheme()
  const { t } = useTranslation()
  return (
    <TouchableOpacity
      style={{
        width: 100,
        marginBottom: 5,
        backgroundColor: isActive ? colors.tableRowHover : 'transparent'
      }}
      onPress={() => setActiveOption(tabKey)}
    >
      <AnimatedComponent
        hoverOpacity
        hoverColor={colors.tableRowHover}
        style={{
          width: '100%',
          height: 35,
          justifyContent: 'center',
          borderColor: colors.blueBackground,
          borderBottomWidth: 1.5
        }}
      >
        <Text styles={{ textAlign: 'center' }}>{t(label)}</Text>
      </AnimatedComponent>
    </TouchableOpacity>
  )
}
